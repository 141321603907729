@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        mrlaw
Version:        2.0
Last change:    02.20.2016
Primary use:     Insurance and Lawyer business template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
@import "../css/elegant-icons.css";
@import "../css/stroke-gap-icons.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800);
@import url(http://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,200,500,700,600,800);
/*
This file contains the common styles of this theme.
*/
/*------------------------------------------------------------------
[Table of contents]

		* Typography.less
		* Common.less
		* Extra.less
		* Header.less
		* Nav.less
		* Inner-Header-Title.less
		* Vertical-Nav.less
		* Boxed-layout.less
		* Form.less
		* Side-Push-Panel.less
		* Box-Hover-Effect.less
		* Portfolio-Gallery.less
		* Slider.less
		* Home.less
		* About.less
		* Contact.less
		* Event.less
		* Practice-Area.less
		* Services.less
		* Job.less
		* Shop.less
		* Blog.less
		* Shortcode.less 
			* Alerts.less
			* Buttons.less
			* Blockquote.less
			* Clients.less
			* Countdown.less
			* Countdown-Timer.less
			* Dividers.less
			* Facebook-Disqus-Comments.less
			* Faqs.less
			* Flexslider.less
			* Flip-Box.less
			* Font-Icons-Style.less
			* Funfacts.less
			* Icon-Boxes.less
			* Infinitescroll.less
			* Instagram.less
			* Light-Box.less
			* Map.less
			* Pegination.less
			* Pricing.less
			* Process-Steps.less
			* Progress-Bar.less
			* Promo.less
			* Section-Title.less
			* Separators.less
			* Social-Icons.less
			* Tabs.less
			* Teams.less
			* Testimonials.less
			* Toggles-Accordions.less
			* Vertical-Timeline.less
			* Working-Process.less
			* Widgets.less
	* Footer
* ===============================================
*/
/*
 * Typography.less
 * -----------------------------------------------
*/
::selection {
  background: #333333;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #333333;
  /* Firefox */
  color: #fff;
  text-shadow: none;
}
::-webkit-selection {
  background: #333333;
  /* Safari */
  color: #fff;
  text-shadow: none;
}
:active,
:focus {
  outline: none !important;
}
body {
  line-height: 1.7;
  color: #777777;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  background-attachment: fixed;
}
a {
  color: #999999;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #737373;
  text-decoration: none;
}
a img {
  border: none;
}
img {
  max-width: 100%;
}
iframe {
  border: none !important;
}
/* -------- Headings ---------- */
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 15px;
}
h6,
.h6 {
  font-size: 12px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  color: #333333;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #333333;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: #333333;
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-weight: 500;
}
/* -------- Body Text ---------- */
p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
  margin-bottom: 9px;
}
/* -------- Custom Font Family ---------- */
.font-raleway {
  font-family: 'Raleway', sans-serif;
}
.font-opensans {
  font-family: 'Open Sans', sans-serif;
}
.font-Playfair {
  font-family: 'Playfair Display', serif;
}
/* breadcrumb */
.breadcrumb {
  background-color: transparent;
  padding-left: 0;
  text-align: center;
}
.breadcrumb > li + li::before {
  content: "\f22d";
  font-family: fontawesome;
  font-size: 9px;
}
.breadcrumb li.active {
  color: #111111;
}
.breadcrumb.white li.active {
  color: #fff;
}
.breadcrumb.white li a {
  color: #808080;
}
@media only screen and (min-width: 768px) {
  .breadcrumb.text-right {
    text-align: right !important;
  }
}
/*Drop Caps*/
.drop-caps p {
  text-align: justify;
}
.drop-caps p:first-child:first-letter {
  color: #111111;
  display: block;
  float: left;
  font-size: 48px;
  line-height: 48px;
  margin: 6px 3px;
  padding: 10px 18px;
}
.drop-caps.text-colored p:first-child:first-letter {
  display: block;
  float: left;
  font-size: 48px;
  line-height: 48px;
  margin: 6px 3px;
  padding: 10px 18px;
}
.drop-caps.border p:first-child:first-letter {
  border: 1px solid;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
}
.drop-caps.border-rounded p:first-child:first-letter {
  border: 1px solid;
  border-radius: 50%;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
}
.drop-caps.colored-square p:first-child:first-letter {
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
}
.drop-caps.colored-rounded p:first-child:first-letter {
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
  border-radius: 50%;
}
.drop-caps.dark-square p:first-child:first-letter {
  background: #222222;
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
}
.drop-caps.dark-rounded p:first-child:first-letter {
  background: #222222;
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
  border-radius: 50%;
}
/*Text Highlight*/
.text-highlight {
  background: none repeat scroll 0 0 #111111;
  border-radius: 4px;
  color: #fff;
  padding: 0 5px 3px;
}
.text-highlight.light {
  background: #808080;
}
/*
 * 1.1 -> Common.less
 * -----------------------------------------------
*/
/* -------- Basic Structure ---------- */
html {
  font-size: 100%;
  max-width: 100%;
}
html,
html a {
  -webkit-font-smoothing: antialiased;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}
img {
  max-width: 100%;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fluid-video-wrapper {
  padding: 0;
  position: relative;
  width: 100%;
}
iframe {
  border: none;
  width: 100%;
}
.text-highlight {
  background: none repeat scroll 0 0 #111111;
  border-radius: 4px;
  color: #fff;
  padding: 0 5px 3px;
}
.text-highlight.light {
  background: #808080;
}
.inline-block {
  display: inline-block;
}
.display-block {
  display: block;
}
.fullwidth {
  width: 100%;
}
.bg-hover-theme-colored {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/* Text-decoration */
.text-underline {
  text-decoration: underline;
}
/* -------- Text Colors ---------- */
/* Text Black & Gray Color*/
.text-black {
  color: #000 !important;
}
.text-black-111 {
  color: #111111 !important;
}
.text-black-222 {
  color: #222222 !important;
}
.text-black-333 {
  color: #333333 !important;
}
.text-black-444 {
  color: #404040 !important;
}
.text-black-555 {
  color: #555555 !important;
}
.text-gray-dimgray {
  color: #666666 !important;
}
.text-gray-light {
  color: #777777 !important;
}
.text-gray {
  color: #808080 !important;
}
.text-gray-darkgray {
  color: #a9a9a9 !important;
}
.text-gray-silver {
  color: #c0c0c0 !important;
}
.text-gray-lightgray {
  color: #d3d3d3 !important;
}
.text-gray-gainsboro {
  color: #fff !important;
}
.text-gray-lighter {
  color: #eeeeee !important;
}
/* Text White Color*/
.text-white {
  color: #fff !important;
}
.text-white-f1 {
  color: #f1f1f1 !important;
}
.text-white-f3 {
  color: #f3f3f3 !important;
}
.text-white-f7 {
  color: #f7f7f7 !important;
}
.text-white-f8 {
  color: #f8f8f8 !important;
}
.text-white-fa {
  color: #fafafa !important;
}
.text-white-fc {
  color: #fcfcfc !important;
}
/* -------- Solid Color Background ---------- */
.bg-deep {
  background-color: #f1f1f1 !important;
}
.bg-light {
  background-color: #f5f5f5 !important;
}
.bg-lighter {
  background-color: #f7f7f7 !important;
}
.bg-lightest {
  background-color: #fcfcfc !important;
}
.bg-black {
  background-color: #000 !important;
}
.divider-dark {
  background-color: #252525 !important;
}
footer.bg-deep {
  background-color: #191919 !important;
}
.bg-black-111 {
  background-color: #111111 !important;
}
.bg-black-222 {
  background-color: #222222 !important;
}
.bg-black-333 {
  background-color: #333333 !important;
}
.bg-black-444 {
  background-color: #404040 !important;
}
.bg-black-555 {
  background-color: #555555 !important;
}
.bg-gray-dimgray {
  background-color: #666666 !important;
}
.bg-gray-light {
  background-color: #777777 !important;
}
.bg-gray {
  background-color: #808080 !important;
}
.bg-gray-darkgray {
  background-color: #a9a9a9 !important;
}
.bg-gray-silver {
  background-color: #c0c0c0 !important;
}
.bg-gray-lightgray {
  background-color: #d3d3d3 !important;
}
.bg-gray-gainsboro {
  background-color: #fff !important;
}
.bg-gray-lighter {
  background-color: #eeeeee !important;
}
/* Bg White Color*/
.bg-white {
  background-color: #fff !important;
}
.bg-white-f1 {
  background-color: #f1f1f1 !important;
}
.bg-white-f3 {
  background-color: #f3f3f3 !important;
}
.bg-white-f5 {
  background-color: #f5f5f5 !important;
}
.bg-white-f7 {
  background-color: #f7f7f7 !important;
}
.bg-white-f8 {
  background-color: #f8f8f8 !important;
}
.bg-white-fa {
  background-color: #fafafa !important;
}
.bg-white-fb {
  background-color: #fbfbfb !important;
}
.bg-white-fc {
  background-color: #fcfcfc !important;
}
/* -------- Transparent Background Color ---------- */
.bg-deep-transparent {
  background-color: rgba(255, 255, 255, 0.9);
}
.bg-light-transparent {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-lighter-transparent {
  background-color: rgba(255, 255, 255, 0.7);
}
.bg-lightest-transparent {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-very-lightest-transparent {
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-white-transparent-light {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
.bg-white-transparent-deep {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.bg-dark-transparent-light {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.bg-dark-transparent {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.bg-dark-transparent-deep {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
/* -------- Font Weight ---------- */
.font-weight-100 {
  font-weight: 100 !important;
}
.font-weight-200 {
  font-weight: 200 !important;
}
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
/* -------- Font Size ---------- */
.font-9 {
  font-size: 9px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-17 {
  font-size: 17px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-19 {
  font-size: 19px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-21 {
  font-size: 21px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-23 {
  font-size: 23px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-25 {
  font-size: 25px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-27 {
  font-size: 27px !important;
}
.font-28 {
  font-size: 28px !important;
}
.font-29 {
  font-size: 29px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-32 {
  font-size: 32px !important;
}
.font-36 {
  font-size: 36px !important;
}
.font-48 {
  font-size: 48px !important;
}
.font-60 {
  font-size: 60px !important;
}
.font-64 {
  font-size: 64px !important;
}
.font-72 {
  font-size: 72px !important;
}
.font-78 {
  font-size: 78px !important;
}
.font-100 {
  font-size: 100px !important;
}
.font-150 {
  font-size: 150px !important;
}
.font-200 {
  font-size: 200px !important;
}
/* -------- Letter Spacing ---------- */
.letter-space-0 {
  letter-spacing: 0px;
}
.letter-space-1 {
  letter-spacing: 1px;
}
.letter-space-2 {
  letter-spacing: 2px;
}
/* -------- Line Height ---------- */
.line-height-0 {
  line-height: 0px;
}
.line-height-20 {
  line-height: 20px;
}
.line-height-80 {
  line-height: 80px;
}
.line-height-100 {
  line-height: 100px;
}
.line-height-150 {
  line-height: 150px;
}
.line-height-200 {
  line-height: 200px;
}
.line-height-1em {
  line-height: 1em;
}
/* -------- Line-Bottom ---------- */
.heading-line-bottom {
  border-bottom: 1px dashed #808080;
  display: inline-block;
  margin: 0;
  position: relative;
  margin: 20px 0;
}
.heading-line-bottom::after {
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 20px;
}
.heading-title {
  margin-top: 10px;
}
.line-bottom {
  position: relative;
}
.line-bottom:after {
  bottom: -10px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 50px;
}
.line-bottom-centered {
  position: relative;
  margin: 10px 0 20px;
}
.line-bottom-centered:after {
  background: #333 none repeat scroll 0 0;
  content: "";
  height: 2px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
}
/* -------- Custom Margin Padding ---------- */
.mb-5 {
  margin-bottom: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.p-25 {
  padding: 25px !important;
}
/* -------- Border ---------- */
.no-border {
  border: none !important;
}
.border-1px {
  border: 1px solid #eeeeee;
}
.border-2px {
  border: 2px solid #eeeeee;
}
.border-3px {
  border: 3px solid #eeeeee;
}
.border-4px {
  border: 4px solid #eeeeee;
}
.border-5px {
  border: 5px solid #eeeeee;
}
.border-left {
  border-left: 1px solid #dcdcdc;
}
.border-right {
  border-right: 1px solid #dcdcdc;
}
.border-top {
  border-top: 1px solid #dcdcdc;
}
.border-bottom {
  border-bottom: 1px solid #eeeeee;
}
.border-bottom-gray {
  border-bottom: 1px solid #d3d3d3;
}
.border-gray {
  border-color: #eeeeee !important;
}
/* -------- List ---------- */
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul ul,
ol ul {
  margin-left: 15px;
}
ul.list {
  list-style: outside none none;
  margin-top: 13px;
  padding-left: 0;
}
ul.list li {
  margin: 5px 0;
}
ul.list li:before {
  border: none;
  color: #a9a9a9;
  content: "\f111";
  display: inline-block;
  font-family: fontawesome;
  font-size: 9px;
  margin-right: 10px;
  padding: 0;
}
ul.list li:hover:before {
  background: none;
  border-color: transparent;
}
ul.list li a {
  text-decoration: none;
  -webkit-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
/* -------- List Icons ---------- */
.list-icon li {
  font-size: 14px;
  margin: 0;
}
.list-icon li i {
  color: #555555;
  font-size: 14px;
  height: 28px;
  line-height: 28px;
  margin: 5px 10px 5px 0;
  text-align: center;
  width: 28px;
}
.list-icon.square li i {
  background: #c0c0c0;
  color: #555555;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin: 5px 10px 5px 0;
  text-align: center;
  width: 28px;
}
.list-icon.rounded li i {
  color: #555555;
  background: #c0c0c0;
  border-radius: 50%;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin: 5px 10px 5px 0;
  text-align: center;
  width: 28px;
}
.list-icon.theme-colored.square li i {
  color: #fff;
}
.list-icon.theme-colored.rounded li i {
  color: #fff;
}
/* -------- Personal Info List ---------- */
.list-info li {
  clear: both;
  position: relative;
}
.list-info li label {
  display: inline-block;
  font-weight: bold;
  padding: 5px 0 0;
}
.list-info li span {
  left: 110px;
  padding: 5px 0;
  position: absolute;
}
.list-features {
  -moz-column-count: 3;
  column-count: 3;
}
.list-dashed > article {
  border-bottom: 1px dashed #d3d3d3 !important;
  margin-bottom: 7px;
  padding-bottom: 7px;
}
.list-dashed > div {
  border-bottom: 1px dashed #d3d3d3 !important;
  margin-bottom: 7px;
  padding-bottom: 7px;
}
.list-dashed > li {
  border-bottom: 1px dashed #d3d3d3 !important;
  margin-bottom: 7px;
  padding-bottom: 7px;
}
.list-dashed.dark-dash > article {
  border-bottom: 1px dashed #333333 !important;
}
.list-dashed.dark-dash > div {
  border-bottom: 1px dashed #333333 !important;
}
.list-dashed.dark-dash > li {
  border-bottom: 1px dashed #333333 !important;
}
ul.list.check li:before {
  content: "\f00c";
  font-family: fontawesome;
  font-size: 12px;
}
ul.list.angle-double-right li:before {
  content: "\f101";
  font-family: fontawesome;
  font-size: 12px;
}
ul.list.angle-right li:before {
  content: "\f105";
  font-family: fontawesome;
}
ul.list.check-circle li:before {
  content: "\f058";
  font-family: fontawesome;
  font-size: 14px;
}
/* -------- Call To Actions ---------- */
.call-to-action {
  display: inline-block;
  width: 100%;
  padding: 20px;
}
/* -------- Section & Divider ---------- */
section {
  position: relative;
  overflow: hidden;
}
section > .container,
section > .container-fluid {
  padding-top: 60px;
  padding-bottom: 60px;
}
/* -------- Background Image ---------- */
.no-bg {
  background: none !important;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-img-fixed {
  background-attachment: fixed;
}
.bg-img-cover {
  background-size: cover;
}
.bg-img-center-bottom {
  background-position: center bottom;
}
.bg-img-center {
  background-position: center center;
}
.bg-img-left-bottom {
  background-repeat: no-repeat;
  background-position: left bottom;
}
.bg-img-right-top {
  background-repeat: no-repeat;
  background-position: right top;
}
.bg-img-left-top {
  background-repeat: no-repeat;
  background-position: left top;
}
.img-fullwidth {
  width: 100%;
}
/* -------- Absolute Image Positioning ---------- */
.overflow-visible {
  overflow: visible !important;
}
.box-absolute {
  position: absolute;
}
.img-absolute-parent {
  position: relative;
}
.img-absolute-parent img.img-absolute {
  position: absolute;
}
.img-absolute-parent img.img-pos-left {
  left: 0;
}
.img-absolute-parent img.img-pos-top {
  top: 0;
}
.img-absolute-parent img.img-pos-bottom {
  bottom: 0;
}
.img-absolute-parent img.img-pos-right {
  right: 0;
}
.img-absolute-parent img.img-pos-center {
  left: 0;
  right: 0;
  margin: 0 auto;
}
/* -------- Background Video ---------- */
.bg-video {
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: -1;
}
.bg-video .video {
  height: 100%;
  left: 0;
  margin: 0!important;
  position: relative;
  top: 0;
  width: 100%;
}
.bg-video .video .mbYTP_wrapper {
  z-index: -1 !important;
}
/* -------- Box Table ---------- */
.display-table-parent {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.display-table {
  display: table;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}
.display-table-cell {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
/* -------- Scroll To Top ---------- */
.scrollToTop {
  background: rgba(0, 0, 0, 0.1);
  bottom: 15px;
  display: none;
  height: 50px;
  padding: 2px;
  position: fixed;
  right: 15px;
  text-align: center;
  text-decoration: none;
  width: 50px;
  z-index: 99999;
}
.scrollToTop i {
  color: #fff;
  font-size: 42px;
}
.scrollToTop:hover {
  background: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
/* -------- Opening Hours ---------- */
.opening-hourse ul li {
  padding: 0 0 15px 0;
}
/* -------- Multi Row Clearfix ---------- */
.multi-row-clearfix .col-xs-6:nth-child(2n + 3) {
  clear: left;
}
.multi-row-clearfix .col-xs-4:nth-child(3n + 4) {
  clear: left;
}
.multi-row-clearfix .col-xs-3:nth-child(4n + 5) {
  clear: left;
}
.multi-row-clearfix .col-xs-2:nth-child(6n + 7) {
  clear: left;
}
.multi-row-clearfix .col-xs-1:nth-child(12n + 13) {
  clear: left;
}
@media (min-width: 768px) {
  /* reset previous grid */
  .multi-row-clearfix .col-xs-6:nth-child(2n + 3) {
    clear: none;
  }
  .multi-row-clearfix .col-xs-4:nth-child(3n + 4) {
    clear: none;
  }
  .multi-row-clearfix .col-xs-3:nth-child(4n + 5) {
    clear: none;
  }
  .multi-row-clearfix .col-xs-2:nth-child(6n + 7) {
    clear: none;
  }
  .multi-row-clearfix .col-xs-1:nth-child(12n + 13) {
    clear: none;
  }
  /* clear first in row for small columns */
  .multi-row-clearfix .col-sm-6:nth-child(2n + 3) {
    clear: left;
  }
  .multi-row-clearfix .col-sm-4:nth-child(3n + 4) {
    clear: left;
  }
  .multi-row-clearfix .col-sm-3:nth-child(4n + 5) {
    clear: left;
  }
  .multi-row-clearfix .col-sm-2:nth-child(6n + 7) {
    clear: left;
  }
  .multi-row-clearfix .col-sm-1:nth-child(12n + 13) {
    clear: left;
  }
}
@media (min-width: 992px) {
  /* reset previous grid */
  .multi-row-clearfix .col-sm-6:nth-child(2n + 3) {
    clear: none;
  }
  .multi-row-clearfix .col-sm-4:nth-child(3n + 4) {
    clear: none;
  }
  .multi-row-clearfix .col-sm-3:nth-child(4n + 5) {
    clear: none;
  }
  .multi-row-clearfix .col-sm-2:nth-child(6n + 7) {
    clear: none;
  }
  .multi-row-clearfix .col-sm-1:nth-child(12n + 13) {
    clear: none;
  }
  /* clear first in row for medium columns */
  .multi-row-clearfix .col-md-6:nth-child(2n + 3) {
    clear: left;
  }
  .multi-row-clearfix .col-md-4:nth-child(3n + 4) {
    clear: left;
  }
  .multi-row-clearfix .col-md-3:nth-child(4n + 5) {
    clear: left;
  }
  .multi-row-clearfix .col-md-2:nth-child(6n + 7) {
    clear: left;
  }
  .multi-row-clearfix .col-md-1:nth-child(12n + 13) {
    clear: left;
  }
}
@media (min-width: 1200px) {
  /* reset previous grid */
  .multi-row-clearfix .col-md-6:nth-child(2n + 3) {
    clear: none;
  }
  .multi-row-clearfix .col-md-4:nth-child(3n + 4) {
    clear: none;
  }
  .multi-row-clearfix .col-md-3:nth-child(4n + 5) {
    clear: none;
  }
  .multi-row-clearfix .col-md-2:nth-child(6n + 7) {
    clear: none;
  }
  .multi-row-clearfix .col-md-1:nth-child(12n + 13) {
    clear: none;
  }
  /* clear first in row for large columns */
  .multi-row-clearfix .col-lg-6:nth-child(2n + 3) {
    clear: left;
  }
  .multi-row-clearfix .col-lg-4:nth-child(3n + 4) {
    clear: left;
  }
  .multi-row-clearfix .col-lg-3:nth-child(4n + 5) {
    clear: left;
  }
  .multi-row-clearfix .col-lg-2:nth-child(6n + 7) {
    clear: left;
  }
  .multi-row-clearfix .col-lg-1:nth-child(12n + 13) {
    clear: left;
  }
}
/* -------- Responsive Classes ---------- */
/* Small Devices, Tablets */
@media only screen and (max-width: 991px) {
  .sm-display-block {
    display: block !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .sm-text-right {
    text-align: right !important;
  }
  .sm-text-left {
    text-align: left !important;
  }
  .sm-pull-none {
    float: none !important;
  }
  .sm-pull-left {
    float: left !important;
  }
  .sm-pull-right {
    float: right !important;
  }
  .sm-fullwidth {
    width: 100%;
  }
  .sm-height-auto {
    min-height: auto !important;
  }
  .sm-hide-bg-img {
    background: none !important;
  }
  .maxwidth400 {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }
  .maxwidth500 {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }
  .maxwidth600 {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  .xs-display-block {
    display: block;
  }
  .xs-text-center {
    text-align: center !important;
  }
  .xs-text-right {
    text-align: right !important;
  }
  .xs-text-left {
    text-align: left !important;
  }
  .xs-fullwidth {
    width: 100%;
  }
  .xs-height-auto {
    min-height: auto !important;
  }
  .xs-hide-bg-img {
    background: none !important;
  }
  .xs-pull-none {
    float: none !important;
  }
  .xs-pull-left {
    float: left !important;
  }
  .xs-pull-right {
    float: right !important;
  }
  .xs-pull-center {
    display: table;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xs-list-inline-none li {
    display: table;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px !important;
    padding-left: 0 !important;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 479px) {
  .xxs-text-center {
    text-align: center;
  }
  .xxs-height-auto {
    min-height: auto !important;
  }
}
/*  
 * Extra.less 
 * -----------------------------------------------
*/
/* -------- Heading Style ---------- */
.heading-border {
  border-bottom: 1px solid #d3d3d3;
  margin: 30px 0;
  padding: 20px 0;
}
/* -------- FullCalendar - Plugin ---------- */
.fc-day-number {
  color: #333333;
}
.fc-day-number.fc-other-month {
  opacity: 0.4;
}
/*
 * Overlays.less
 * -----------------------------------------------
*/
.layer-overlay {
  position: relative;
}
.layer-overlay::before {
  background: rgba(17, 17, 17, 0.65) none repeat scroll 0 0;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.layer-overlay.overlay-light::before {
  background-color: rgba(255, 255, 255, 0.8);
}
.layer-overlay.overlay-lighter::before {
  background-color: rgba(255, 255, 255, 0.7);
}
.layer-overlay.overlay-deep::before {
  background-color: rgba(255, 255, 255, 0.9);
}
.layer-overlay.overlay-dark::before {
  background-color: rgba(17, 17, 17, 0.85);
}
.layer-overlay.overlay-dark-deep::before {
  background-color: rgba(17, 17, 17, 0.9);
}
.layer-overlay.overlay-dark-light::before {
  background-color: rgba(17, 17, 17, 0.8);
}
.layer-overlay.overlay-white::before {
  background-color: rgba(255, 255, 255, 0.9);
}
.layer-overlay.overlay-white-deep::before {
  background-color: rgba(255, 255, 255, 0.92);
}
.layer-overlay.overlay-white-light::before {
  background-color: rgba(255, 255, 255, 0.85);
}
.layer-overlay.layer-pattern::before {
  background-image: url(../images/pattern1.png);
  background-color: rgba(0, 0, 0, 0.6);
}
.layer-overlay.layer-pattern2::before {
  background-image: url(../images/pattern2.png);
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-overlay.layer-pattern3::before {
  background-image: url(../images/pattern3.png);
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-overlay.maximage-layer-overlay::before {
  z-index: 1;
}
.layer-overlay.overlay-blue::before {
  background-color: rgba(1, 162, 208, 0.9);
}
.layer-overlay.overlay-blue-light::before {
  background-color: rgba(1, 162, 208, 0.45);
}
.layer-overlay.overlay-pink::before {
  background-color: rgba(235, 110, 142, 0.9);
}
.layer-overlay.overlay-pink-light::before {
  background-color: rgba(235, 110, 142, 0.45);
}
.layer-overlay.overlay-brown::before {
  background-color: rgba(60, 171, 111, 0.9);
}
.layer-overlay.overlay-brown-light::before {
  background-color: rgba(60, 171, 111, 0.45);
}
.layer-overlay.overlay-yellow::before {
  background-color: rgba(255, 187, 34, 0.9);
}
.layer-overlay.overlay-yellow-light::before {
  background-color: rgba(255, 187, 34, 0.45);
}
.layer-overlay.overlay-green::before {
  background-color: rgba(16, 196, 92, 0.9);
}
.layer-overlay.overlay-green-light::before {
  background-color: rgba(16, 196, 92, 0.45);
}
/*
 * Header.less
 * -----------------------------------------------
*/
.header-top {
  padding: 5px 0;
}
.header-top .header-widget .social-icons a i:hover {
  background: none;
}
.header-top .header-widget .list-border li {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  padding: 5px 15px;
}
.header-top .header-widget .list-border li:last-child {
  border-right: 0;
}
@media only screen and (max-width: 767px) {
  .header-top .header-widget .contact-info {
    margin-bottom: 20px;
  }
  .header-top .header-widget .contact-info li {
    display: block;
    text-align: center;
  }
}
.menuzord-brand img {
  max-height: 38px;
}
/* -------- Inner Header ---------- */
.inner-header .title {
  text-transform: uppercase;
}
/* -------- Custom theme style ---------- */
.header-nav .header-nav-wrapper .menuzord {
  padding: 0;
}
.header-nav.navbar-transparent.animated-active {
  background-color: rgba(0, 0, 0, 0.25);
}
/*
 * Nav.less
 * -----------------------------------------------
*/
/* -------- Nav Sidebar ---------- */
.nav-sidebar li {
  background: #f1f1f1;
  padding-bottom: 0 !important;
}
.nav-sidebar li a {
  border-radius: 0;
}
.nav-pills li a:hover,
.nav-pills li.active > a,
.nav-pills li.active > a:hover,
.nav-pills li.active > a:focus {
  background: #d3d3d3;
  color: #777777;
}
/* -------- Header Nav ---------- */
.header-nav {
  position: relative;
}
.header-nav .header-nav-absolute-wrapper {
  width: 100%;
}
.header-nav .header-nav-wrapper .menuzord {
  padding: 0;
}
/* -------- Menuzord ---------- */
.menuzord-menu > li > a {
  font-size: 13px;
  font-weight: 400;
}
.menuzord .menuzord-menu > li.active > a i,
.menuzord .menuzord-menu > li:hover > a i,
.menuzord .menuzord-menu ul.dropdown li:hover > a i {
  color: #fff;
}
.menuzord .menuzord-menu ul.dropdown,
.menuzord .menuzord-menu .megamenu {
  border: 1px solid #f0f0f0;
}
.menuzord-menu ul.dropdown li {
  margin: 0;
}
.menuzord-menu ul.dropdown li a {
  padding: 7px 25px 7px 22px;
}
.menuzord-menu ul.dropdown li .indicator {
  top: 4px;
}
/* -------- Megamenu-Row ---------- */
.menuzord-menu > li > .megamenu .megamenu-row li {
  border: 0 none;
  clear: both;
  font-size: 12px;
  margin: 0;
  padding: 2px;
  position: relative;
  width: 100%;
}
.menuzord-menu > li > .megamenu .megamenu-row li a {
  display: block;
  padding: 8px 10px;
  -webkit-transition: padding 0.2s linear;
  -o-transition: padding 0.2s linear;
  transition: padding 0.2s linear;
}
.menuzord-menu > li > .megamenu .megamenu-row li.active > a,
.menuzord-menu > li > .megamenu .megamenu-row li:hover > a {
  color: #111;
  padding-left: 15px;
}
.menuzord-menu > li > .megamenu .megamenu-row li a i {
  color: #444;
}
.menuzord-menu > li > .megamenu .megamenu-row li.active a i,
.menuzord-menu > li > .menuzord-menu > li > .megamenu .megamenu-row li:hover a i {
  color: #fff;
}
@media only screen and (max-width: 900px) {
  .menuzord-menu {
    background: #fff;
  }
}
/* -------- Scrolltofixed ---------- */
.header-nav .header-nav-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
  border-bottom: 1px solid #eee;
}
.header-nav .header-nav-absolute-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed .menuzord {
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
}
.header .inner-sticky-wrapper {
  height: 80px;
}
@media only screen and (min-width: 901px) {
  .header-nav .header-nav-absolute-wrapper {
    bottom: -36px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    z-index: 1111;
  }
  .header-nav .header-nav-absolute-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
    bottom: auto;
  }
}
/* -------- Navbar Fixed  ---------- */
.header-nav {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.header-nav.navbar-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 1030;
}
/* -------- Navbar Sticky Animated ---------- */
.navbar-sticky-animated .header-nav-wrapper .container {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
/* -------- Header White ---------- */
.header-nav.navbar-white {
  background-color: rgba(255, 255, 255, 0.95);
  border: medium none;
}
.header-nav.navbar-white .menuzord {
  background: transparent;
}
/* -------- Header Transparent ---------- */
.header-nav.navbar-transparent .menuzord {
  background: transparent;
}
.header-nav.navbar-transparent .header-nav-wrapper {
  border-bottom: 1px solid #eee;
}
.header-nav.navbar-transparent.animated-active {
  background-color: rgba(0, 0, 0, 0.1);
}
.header-nav.navbar-transparent.animated-active .header-nav-wrapper {
  border-bottom: none !important;
}
.header-nav.navbar-transparent.animated-active .menuzord-menu > li > a {
  color: #fff;
}
@media only screen and (max-width: 900px) {
  .header-nav.navbar-transparent.animated-active .menuzord-menu > li > a {
    color: #888;
  }
}
/*
 * Inner-Header-Title.less
 * -----------------------------------------------
*/
.inner-header .title {
  text-transform: uppercase;
}
/*
 *  Vertical-Nav.less
 * -----------------------------------------------
*/
.vertical-nav {
  /* vertical-nav-black*/
}
.vertical-nav .header {
  width: 260px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: #fff;
  z-index: 101;
  padding: 0;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  background-size: cover !important;
}
.vertical-nav .main-content,
.vertical-nav .footer {
  margin-left: 260px;
}
.vertical-nav .container {
  max-width: 940px;
}
.vertical-nav .menuzord {
  background: none;
}
.vertical-nav .menuzord .menuzord-menu ul.dropdown li:hover > a {
  background: #eeeeee none repeat scroll 0 0;
  color: #111111;
}
.vertical-nav .menuzord-menu li {
  padding: 5px 0;
  position: relative;
  width: 100%;
}
.vertical-nav .menuzord-menu li .indicator {
  float: right;
}
.vertical-nav .menuzord-menu li a {
  padding-left: 30px;
  width: 100%;
}
.vertical-nav .menuzord-menu ul.dropdown,
.vertical-nav .menuzord-menu .megamenu {
  left: 100%;
  top: 4px;
}
.vertical-nav .menuzord-brand img {
  max-height: none;
}
.vertical-nav .vertical-nav-black {
  background: #111111;
}
.vertical-nav .vertical-nav-black .menuzord-menu ul.dropdown {
  background: #111111;
  border-color: #222222;
}
.vertical-nav .vertical-nav-black .menuzord-menu ul.dropdown li a {
  background: #111111;
}
.vertical-nav .vertical-nav-black .menuzord-menu ul.dropdown li a .indicator i {
  color: #fff;
}
.vertical-nav .vertical-nav-black .menuzord-menu ul.dropdown li:hover > a {
  background: #111111 none repeat scroll 0 0;
  color: #fff;
}
.vertical-nav .vertical-nav-black .menuzord-menu > li > a {
  color: #808080;
}
.vertical-nav.vertical-nav-right .header {
  left: auto;
  right: 0;
}
.vertical-nav.vertical-nav-right .main-content,
.vertical-nav.vertical-nav-right .footer {
  margin-left: auto;
  margin-right: 260px;
  padding-top: 0;
}
.vertical-nav.vertical-nav-right .menuzord-menu ul.dropdown,
.vertical-nav.vertical-nav-right .menuzord-menu .megamenu {
  left: auto !important;
  right: 100% !important;
  top: 0;
}
@media only screen and (max-width: 1199px) {
  .vertical-nav .container {
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .vertical-nav .header {
    border: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .vertical-nav .vertical-nav-widget {
    display: none;
  }
  .vertical-nav .menuzord {
    background: #fff;
  }
  .vertical-nav .vertical-nav-black .menuzord {
    background: none;
  }
  .vertical-nav .menuzord-brand {
    margin: 0 !important;
    padding: 0 !important;
  }
  .vertical-nav .menuzord-brand img {
    max-height: 65px !important;
    padding: 10px;
  }
  .vertical-nav .header-nav-wrapper {
    background: #111111 none repeat scroll 0 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .vertical-nav .vertical-nav-black .menuzord .menuzord-menu li > a,
  .vertical-nav .vertical-nav-black .menuzord .menuzord-menu li .indicator {
    border-color: #222222;
  }
  .vertical-nav .main-content,
  .vertical-nav .footer {
    margin-left: 0;
  }
  .vertical-nav.vertical-nav-right .main-content,
  .vertical-nav.vertical-nav-right .footer {
    margin-right: 0;
  }
}
/*
 *  menu-full-page.less
 * -----------------------------------------------
*/
/* menu-full-page plugin code */
.menu-full-page #fullpage-nav {
  background: rgba(255, 255, 255, 0.97);
  z-index: -1;
  position: fixed;
  /*left: -100%;*/
  /*left or right and the width of your navigation panel*/
  left: 0 !important;
  /*left or right and the width of your navigation panel*/
  opacity: 0;
  width: 100%;
  /*should match the above value*/
  -webkit-transition: all 0.5s ease-in-out !important;
  -moz-transition: all 0.5s ease-in-out !important;
  -ms-transition: all 0.5s ease-in-out !important;
  -o-transition: all 0.2s ease-in-out !important;
  transition: all 0.5s ease-in-out !important;
}
.menu-full-page #fullpage-nav ul {
  margin-top: 50px;
  -webkit-transition: all 0.7s ease-in-out !important;
  -moz-transition: all 0.7s ease-in-out !important;
  -ms-transition: all 0.7s ease-in-out !important;
  -o-transition: all 0.7s ease-in-out !important;
  transition: all 0.7s ease-in-out !important;
}
.menu-full-page #fullpage-nav li {
  text-align: center;
  transition: all 0.4s ease;
  padding: 10px 0;
  position: relative;
}
.menu-full-page #fullpage-nav li a {
  color: #111;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0;
  position: relative;
}
.menu-full-page #fullpage-nav li a:hover {
  color: #888;
  -webkit-transition: color 2s time;
  -o-transition: color 2s time;
  transition: color 2s time;
}
.menu-full-page #fullpage-nav li:hover:after a {
  position: absolute;
  content: '';
  color: #FFF;
}
.menu-full-page #fullpage-nav ul ul {
  display: none;
}
.menu-full-page #fullpage-nav ul ul li a {
  font-size: 13px;
}
.menu-full-page #fullpage-nav ul ul li a:hover {
  color: #888;
}
.menu-full-page .fullpage-nav-toggle.menu-open + #fullpage-nav {
  opacity: 1;
  z-index: 998;
}
.menu-full-page .fullpage-nav-toggle.menu-open + #fullpage-nav > ul {
  margin-top: 70px;
}
.menu-full-page .fullpage-nav-toggle {
  z-index: 999;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 70px;
  height: 70px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  background-color: transparent;
  /* active state, i.e. menu open */
}
.menu-full-page .fullpage-nav-toggle i {
  text-decoration: none;
  font-size: 40px;
  color: #fff;
}
.menu-full-page .fullpage-nav-toggle:focus {
  outline: none;
}
.menu-full-page .fullpage-nav-toggle span {
  display: block;
  position: absolute;
  top: 45px;
  left: 18px;
  right: 18px;
  height: 4px;
  border-radius: 2px;
  background: #888;
  -webkit-transition: background 0 0.3s;
  transition: background 0 0.3s;
}
.menu-full-page .fullpage-nav-toggle span:before,
.menu-full-page .fullpage-nav-toggle span:after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 0;
  background-color: #888;
  content: "";
  -webkit-transition-duration: 0.3s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  -webkit-transition-delay: 0.3s, 0;
  transition-delay: 0.3s, 0;
}
.menu-full-page .fullpage-nav-toggle span:before {
  top: -12px;
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, transform;
}
.menu-full-page .fullpage-nav-toggle span:after {
  bottom: -12px;
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
}
.menu-full-page .fullpage-nav-toggle.menu-open {
  background-color: transparent;
}
.menu-full-page .fullpage-nav-toggle.menu-open span {
  background: none;
}
.menu-full-page .fullpage-nav-toggle.menu-open span:before,
.menu-full-page .fullpage-nav-toggle.menu-open span:after {
  background-color: #111;
  -webkit-transition-delay: 0, 0.3s;
  transition-delay: 0, 0.3s;
}
.menu-full-page .fullpage-nav-toggle.menu-open span:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menu-full-page .fullpage-nav-toggle.menu-open span:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
/* menu-full-page custom code */
.menu-full-page .header #fullpage-nav li {
  padding: 5px 0;
}
.menu-full-page .header #fullpage-nav li a {
  background: none;
  display: inline;
  font-size: 18px;
  text-transform: none;
}
.menu-full-page .header #fullpage-nav ul ul {
  margin-top: 0;
}
.menu-full-page .header #fullpage-nav ul ul li {
  padding: 3px 0;
}
.menu-full-page .header #fullpage-nav ul ul li a {
  font-size: 12px;
}
.menu-full-page .header #fullpage-nav ul li .dropdown {
  background: none;
  box-shadow: none;
  border: none;
  float: none;
  padding: 0;
  position: relative;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  -moz-transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  -ms-transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  -o-transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  display: block!important;
  visibility: visible;
}
.menu-full-page .header #fullpage-nav ul li:hover .dropdown {
  max-height: 700px;
}
/*
 *  Boxed-layout.less
 * -----------------------------------------------
*/
.boxed-layout .container {
  max-width: 1100px;
  padding-left: 30px;
  padding-right: 30px;
}
.boxed-layout .header,
.boxed-layout .main-content,
.boxed-layout .footer {
  margin: 0 auto;
  max-width: 1100px;
}
.boxed-layout .header,
.boxed-layout section,
.boxed-layout .footer {
  background: none repeat scroll 0 0 #fff;
}
.boxed-layout .header {
  margin-bottom: 15px;
}
.boxed-layout .header .navbar-default {
  background-color: #fff;
  border-color: #fff;
  padding: 0;
}
.boxed-layout .header .navbar-default .navbar-nav > li > a {
  padding: 30px 5px;
}
.boxed-layout .header .navbar-default .navbar-collapse {
  background-color: #fff;
  border-color: #fff;
  margin-right: 30px;
}
.boxed-layout .header #header-logo {
  margin: 0;
  padding-top: 27px;
}
@media only screen and (max-width: 991px) {
  .boxed-layout {
    padding-top: 0;
  }
  .boxed-layout .header .navbar-default .navbar-nav > li > a {
    padding: 10px;
  }
  .boxed-layout .header .navbar-default .navbar-collapse {
    margin-right: 15px;
  }
  .boxed-layout .navbar-header {
    padding: 15px 0;
  }
  .boxed-layout .navbar-collapse .navbar-nav li a .caret {
    margin-right: 0;
  }
}
/*
 * Form.less
 * -----------------------------------------------
*/
/* -------- Form ---------- */
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  font-size: 14px;
}
.form-control {
  border-radius: 0;
  box-shadow: none;
  height: 45px;
}
label {
  font-weight: normal;
}
.form-control:focus,
form input[type="text"]:focus,
form input[type="email"]:focus,
form input[type="number"]:focus,
form input[type="url"]:focus,
form input[type="search"]:focus,
form input[type="tel"]:focus,
form input[type="password"]:focus,
form input[type="date"]:focus,
form input[type="color"]:focus,
form select:focus,
form textarea:focus {
  color: #404040;
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  outline: none;
}
.form-transparent .form-control {
  background: transparent;
  color: #f7f7f7;
}
.form-transparent .form-control:focus,
.form-transparent form input[type="text"]:focus,
.form-transparent form input[type="email"]:focus,
.form-transparent form input[type="number"]:focus,
.form-transparent form input[type="url"]:focus,
.form-transparent form input[type="search"]:focus,
.form-transparent form input[type="tel"]:focus,
.form-transparent form input[type="password"]:focus,
.form-transparent form input[type="date"]:focus,
.form-transparent form input[type="color"]:focus,
.form-transparent form select:focus,
.form-transparent form textarea:focus {
  color: #f7f7f7;
}
.form-transparent textarea.form-control,
.form-white textarea.form-control {
  height: 150px;
}
.form-white .form-control {
  background: #fff;
}
.form-transparent.form-line .form-control {
  border: none;
  border-bottom: 1px solid #c0c0c0;
  padding-left: 0;
}
.form-transparent.form-line textarea.form-control {
  height: 70px;
}
form label {
  font-weight: bold;
}
/* form error */
form .form-control.error {
  border: 2px solid #ee163b;
}
form .error {
  color: #ee163b;
}
/*
 *  Side-Push-Panel.less
 * -----------------------------------------------
*/
.side-push-panel {
  overflow-x: hidden;
}
.side-push-panel.fullwidth-page #wrapper {
  right: 0;
  position: relative;
}
.side-push-panel.fullwidth-page header .header-nav-wrapper.scroll-to-fixed-fixed .container {
  right: 0;
}
.side-push-panel.fullwidth-page.side-panel-left #wrapper {
  left: 0;
  right: auto;
}
.side-push-panel.fullwidth-page.side-panel-left header .header-nav-wrapper.scroll-to-fixed-fixed .container {
  left: 0;
  right: auto;
}
.side-push-panel.fullwidth-page.side-panel-open #wrapper {
  right: 300px;
}
.side-push-panel.fullwidth-page.side-panel-open header .header-nav-wrapper.scroll-to-fixed-fixed .container {
  right: 300px;
}
.side-push-panel.fullwidth-page.side-panel-left.side-panel-open #wrapper {
  left: 300px;
  right: auto;
}
.side-push-panel.fullwidth-page.side-panel-left.side-panel-open header .header-nav-wrapper.scroll-to-fixed-fixed .container {
  left: 300px;
  right: auto;
}
/* -------- Has Side Panel ---------- */
.side-push-panel.has-side-panel .menuzord .showhide {
  float: left;
}
/* -------- Body Overlay ---------- */
.has-side-panel .body-overlay {
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}
.has-side-panel.side-panel-open .body-overlay {
  opacity: 1;
  pointer-events: auto;
  z-index: 1111;
}
.side-panel-open:not(.device-xxs):not(.device-xs):not(.device-sm) .body-overlay:hover {
  cursor: url('../images/close.png') 15 15, default;
}
/* -------- Side Panel ---------- */
#side-panel {
  -webkit-backface-visibility: hidden;
  background-color: #f3f3f3;
  height: 100%;
  overflow: hidden;
  position: fixed;
  right: -300px;
  top: 0;
  width: 300px;
  z-index: 1112;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#side-panel .side-panel-wrap {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: auto;
  padding: 50px 70px 50px 40px;
  position: absolute;
  top: 0;
  width: 330px;
}
#side-panel .widget {
  border-top: 1px solid #eeeeee;
  margin-top: 40px;
  padding-top: 40px;
  width: 220px;
}
#side-panel .widget:first-child {
  border-top: 0;
  margin: 0;
  padding-top: 0;
}
.side-panel-open #side-panel {
  right: 0;
}
.side-panel-left #side-panel {
  left: -300px;
  right: auto;
}
.side-panel-left.side-panel-open #side-panel {
  left: 0;
  right: auto;
}
.device-touch #side-panel .side-panel-wrap {
  overflow-y: scroll;
}
/* -------- Side-Panel-Nav ---------- */
#side-panel .side-panel-nav .nav li a {
  padding: 5px 0;
}
#side-panel .side-panel-nav .nav li a:hover {
  background-color: transparent;
  color: #a9a9a9;
}
#side-panel .side-panel-nav .nav ul.tree {
  display: none;
  margin-left: 15px;
}
/* -------- Side-Panel-Trigger And Close ---------- */
#side-panel-trigger {
  cursor: pointer;
  display: block;
  float: right;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 25px;
  z-index: 11;
}
@media only screen and (max-width: 767px) {
  #side-panel-trigger {
    margin-top: 15px;
  }
}
#side-panel-trigger-close {
  left: 0;
  margin: 10px;
  position: absolute;
  top: 0;
}
/* -------- Transition ---------- */
.side-push-panel.fullwidth-page #wrapper,
.side-push-panel.fullwidth-page header .header-nav-wrapper.scroll-to-fixed-fixed .container {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
/*
 *  Box-Hover-Effect.less
 * -----------------------------------------------
*/
.box-hover-effect {
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
}
.box-hover-effect img {
  position: relative;
  display: block;
  max-height: 100%;
  max-width: 100%;
  opacity: 1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay::before,
.box-hover-effect .overlay::after {
  pointer-events: none;
}
.box-hover-effect .overlay .details {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay .title {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay .para {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay .entry-meta {
  bottom: 10px;
  font-size: 70%;
  opacity: 0;
  position: absolute;
  width: 89%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay .entry-meta.top {
  top: 10px;
}
.box-hover-effect .overlay .entry-meta i {
  color: #fff;
}
.box-hover-effect .overlay .icons {
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 40%;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect:hover .overlay {
  background: rgba(0, 0, 0, 0.85);
}
.box-hover-effect:hover .overlay.white {
  background: rgba(255, 255, 255, 0.75);
}
.box-hover-effect:hover .overlay.black {
  background: rgba(0, 0, 0, 0.85);
}
.box-hover-effect:hover .overlay .entry-meta {
  opacity: 1;
}
.box-hover-effect:hover .overlay .icons {
  opacity: 1;
}
.box-hover-effect:hover .overlay .icons i {
  color: #fff;
}
.box-hover-effect:hover .overlay .icons.twin i {
  padding: 10px;
}
.box-hover-effect:hover .overlay .icons.bordered i {
  border: 1px solid #fff;
}
.box-hover-effect:hover .overlay .icons.bordered i.text-black {
  border-color: #000;
}
.shadow-overlay {
  background-image: url("../images/shadow-overlay.png");
  background-position: center bottom;
  background-repeat: repeat-x;
  bottom: 0;
  color: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}
/* Individual effects */
/* -------- effect1 ---------- */
.box-hover-effect.effect1 .overlay {
  position: relative;
}
.box-hover-effect.effect1 .thumb {
  position: relative;
}
.box-hover-effect.effect1 .thumb:after {
  background-color: rgba(255, 255, 255, 0.15);
  bottom: 0;
  content: "";
  height: 0;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 0;
}
.box-hover-effect.effect1 .thumb:before {
  background-color: rgba(255, 255, 255, 0.15);
  left: 0;
  content: "";
  height: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 0;
  z-index: 1;
}
.box-hover-effect.effect1:hover .thumb:before,
.box-hover-effect.effect1:hover .thumb:after {
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
/* -------- effect2 ---------- */
.box-hover-effect.effect2 .thumb {
  position: relative;
  overflow: hidden;
}
.box-hover-effect.effect2 .overlay .overlay-details {
  opacity: 0;
  -webkit-transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
  -o-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.box-hover-effect.effect2:hover .overlay-details {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.box-hover-effect.effect2:hover .details {
  opacity: 0;
}
.box-hover-effect.effect2:hover img {
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
/* -------- Custom Theme Style ---------- */
.box-hover-effect.effect2 .overlay.white .overlay-details p {
  color: #777777;
}
/*
 * Portfolio-Gallery.less
 * -----------------------------------------------
*/
/* -------- Portfolio Isotop Filter ---------- */
.portfolio-filter {
  margin-bottom: 30px;
  text-align: center;
}
.portfolio-filter a {
  color: #555555;
  display: inline-block;
  float: none;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
  outline: medium none;
  padding: 5px 15px;
}
.portfolio-filter a.active,
.portfolio-filter a:hover {
  color: #111111;
  background-color: #f5f5f5;
}
/* -------- Portfolio Isotop Gallery ---------- */
.portfolio-gallery {
  /*gutter*/
  /* by default 4 grids */
  /*portfolio 2 grids*/
  /*portfolio 2 grids*/
  /*portfolio 3 grids*/
  /*portfolio 4 grids*/
  /*portfolio 5 grids*/
  /*portfolio 6 grids*/
  /*portfolio 7 grids*/
  /*portfolio 8 grids*/
  /*portfolio 9 grids*/
  /*portfolio 10 grids*/
}
.portfolio-gallery.gutter .portfolio-item {
  padding-right: 5px;
  padding-bottom: 5px;
}
.portfolio-gallery.gutter-small .portfolio-item {
  padding-right: 2px;
  padding-bottom: 2px;
}
.portfolio-gallery.gutter-30 .portfolio-item {
  padding-right: 30px;
  padding-bottom: 30px;
}
.portfolio-gallery .portfolio-item {
  width: 25%;
}
.portfolio-gallery .portfolio-item.wide {
  width: 50%;
}
.portfolio-gallery.grid-1 .portfolio-item {
  width: 100%;
}
.portfolio-gallery.grid-1 .portfolio-item.wide {
  width: 100%;
}
.portfolio-gallery.grid-2 .portfolio-item {
  width: 50%;
}
.portfolio-gallery.grid-2 .portfolio-item.wide {
  width: 100%;
}
.portfolio-gallery.grid-3 .portfolio-item {
  width: 33.333%;
}
.portfolio-gallery.grid-3 .portfolio-item.wide {
  width: 66.6667%;
}
.portfolio-gallery.grid-4 .portfolio-item {
  width: 25%;
}
.portfolio-gallery.grid-4 .portfolio-item.wide {
  width: 50%;
}
.portfolio-gallery.grid-5 .portfolio-item {
  width: 20%;
}
.portfolio-gallery.grid-5 .portfolio-item.wide {
  width: 40%;
}
.portfolio-gallery.grid-6 .portfolio-item {
  width: 16.666666667%;
}
.portfolio-gallery.grid-6 .portfolio-item.wide {
  width: 33.333%;
}
.portfolio-gallery.grid-7 .portfolio-item {
  width: 14.2857143%;
}
.portfolio-gallery.grid-7 .portfolio-item.wide {
  width: 28.5714286%;
}
.portfolio-gallery.grid-8 .portfolio-item {
  width: 12.5%;
}
.portfolio-gallery.grid-8 .portfolio-item.wide {
  width: 25%;
}
.portfolio-gallery.grid-9 .portfolio-item {
  width: 11.11%;
}
.portfolio-gallery.grid-9 .portfolio-item.wide {
  width: 22.22%;
}
.portfolio-gallery.grid-10 .portfolio-item {
  width: 10%;
}
.portfolio-gallery.grid-10 .portfolio-item.wide {
  width: 20%;
}
/* -------- Portfolio Isotop Item Media Query ---------- */
@media only screen and (max-width: 991px) {
  .portfolio-gallery .portfolio-item {
    width: 33.333% !important;
  }
  .portfolio-gallery .portfolio-item.wide {
    width: 66.6667% !important;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio-gallery .portfolio-item {
    width: 50% !important;
  }
  .portfolio-gallery .portfolio-item.wide {
    width: 100% !important;
  }
}
@media only screen and (max-width: 479px) {
  .portfolio-gallery .portfolio-item {
    width: 100% !important;
  }
  .portfolio-gallery .portfolio-item.wide {
    width: 100% !important;
  }
}
/* -------- Portfolio Isotop Overlay Effect ---------- */
.portfolio-gallery .portfolio-item {
  overflow: hidden;
}
.portfolio-gallery .portfolio-item .thumb {
  position: relative;
  overflow: hidden;
}
.portfolio-gallery .portfolio-item .hover-link {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  background: none!important;
  color: transparent!important;
  z-index: 110;
}
.portfolio-gallery .portfolio-item .overlay-shade {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #333;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.portfolio-gallery .portfolio-item:hover .overlay-shade {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.portfolio-gallery .portfolio-item .icons-holder {
  position: absolute;
  top: 0%;
  left: 50%;
  display: inline-block;
  width: auto;
  height: 36px;
  vertical-align: middle;
  z-index: 130;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio-gallery .portfolio-item .icons-holder .icons-holder-inner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: -18px 0 0 -50%;
}
.portfolio-gallery .portfolio-item .icons-holder .icons-holder-inner a {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio-gallery .portfolio-item:hover .icons-holder {
  top: 50%;
}
.portfolio-gallery .portfolio-item:hover .icons-holder .icons-holder-inner a {
  opacity: 1;
  filter: alpha(opacity=1);
}
.portfolio-gallery .portfolio-item .portfolio-description {
  padding: 15px 0;
}
.portfolio-gallery .portfolio-item .portfolio-description .title {
  margin: 0;
}
.portfolio-gallery .portfolio-item .portfolio-description .title a {
  color: #333333;
}
.portfolio-gallery .portfolio-item .text-holder {
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 25px 30px 60px;
  position: absolute;
  top: 0;
  -webkit-transition: opacity 0.3s ease-in-out 0s;
  -o-transition: opacity 0.3s ease-in-out 0s;
  transition: opacity 0.3s ease-in-out 0s;
  width: 100%;
  z-index: 100;
}
.portfolio-gallery .portfolio-item .text-holder .title {
  color: #fff;
}
.portfolio-gallery .portfolio-item:hover .text-holder {
  opacity: 1;
}
.portfolio-gallery .flex-control-nav {
  z-index: 111;
}
/*
 * Slider.less
 * -----------------------------------------------
*/
/* -------- Rev Slider ---------- */
#home .revslider-fullscreen {
  min-height: auto;
}
/* -------- Home Revolution Slider For Minimal Layouts ---------- */
.minimal-revslider-wrapper {
  left: 0!important;
  width: 100%!important;
}
.minimal-revslider-wrapper .tp-leftarrow {
  left: 0!important;
}
.minimal-revslider-wrapper .tp-rightarrow {
  right: 0!important;
}
/* -------- Tab-Slider ---------- */
.tab-slider .tab-content {
  padding: 0;
  border: 0;
}
.tab-slider .nav.nav-pills > div {
  background-color: #fff;
  float: left;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tab-slider .nav.nav-pills i {
  color: #d3d3d3;
}
.tab-slider .nav.nav-pills a {
  display: block;
  padding: 40px 30px;
  background: #f3f3f3;
}
.tab-slider .nav.nav-pills a:hover i,
.tab-slider .nav.nav-pills a:hover h6,
.tab-slider .nav.nav-pills a.active i,
.tab-slider .nav.nav-pills a.active h6 {
  color: #fff;
}
/* -------- MaxImage Slider ---------- */
.maximage-slider .fullscreen-controls .img-prev,
.maximage-slider .fullscreen-controls .img-next {
  background: rgba(0, 0, 0, 0.3);
}
.clients-logo.carousel {
  text-align: center;
}
.clients-logo.carousel .item {
  box-shadow: none;
  margin: 0 10px;
}
/* -------- Testimonial-Carousel ---------- */
.testimonial-carousel {
  /* fullwidth */
}
.testimonial-carousel .quote,
.testimonial-carousel .thumb {
  margin-bottom: 40px;
}
.testimonial-carousel .quote i {
  background: #555555;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  height: 60px;
  line-height: 1;
  padding: 20px 0 0;
  text-align: center;
  width: 60px;
}
.testimonial-carousel .author {
  margin-top: 30px;
  text-transform: uppercase;
}
.testimonial-carousel.owl-theme .owl-controls {
  margin-top: 10px;
}
.testimonial-carousel.fullwidth .content p {
  font-size: 15px;
  font-style: italic;
  color: #fff;
}
.testimonial-carousel.fullwidth .content p::after {
  content: "\f10e";
  font-family: fontawesome;
  font-size: 100px;
  left: 42%;
  opacity: 0.1;
  position: absolute;
}
.testimonial-carousel.bullet-white .owl-dots span {
  background: #fff !important;
}
.testimonial-carousel .owl-item img {
  display: inline-block;
  width: auto;
  max-width: 90px;
}
.testimonial-carousel.boxed .quote,
.testimonial-carousel.boxed .thumb {
  margin-bottom: 20px;
}
.testimonial-carousel.boxed .owl-item img {
  display: inline-block;
  width: auto;
  max-width: 65px;
}
.testimonial-carousel.boxed .content::after {
  bottom: 12px;
  content: "\f10e";
  font-family: fontawesome;
  font-size: 55px;
  opacity: 0.2;
  position: absolute;
  right: 10px;
}
.testimonial-carousel.boxed .owl-controls {
  margin-top: 0;
}
.testimonial-carousel.boxed .owl-dots {
  text-align: left;
}
.testimonial-carousel.boxed.owl-theme .owl-dots .owl-dot.active span,
.testimonial-carousel.boxed.owl-theme .owl-dots .owl-dot:hover span {
  opacity: 1;
}
/* -------- Widget-Testimonial-Carousel ---------- */
.widget-testimonial-carousel .item img {
  max-width: 75px;
  float: right;
}
/* -------- Attorney-Carousel ---------- */
.attorney-carousel .author {
  margin-top: 15px;
  text-transform: uppercase;
}
.attorney-carousel .content p {
  color: #666666;
}
.attorney-carousel .contact-area li a {
  font-size: 12px;
  margin-right: 10px;
}
.attorney-carousel .contact-area li i {
  font-size: 14px;
  margin-right: 10px;
}
.attorney-carousel .owl-controls {
  top: -35px;
  left: 0;
  margin: 0;
  position: absolute;
}
.attorney-carousel .owl-controls .owl-nav > div {
  top: 95%;
}
.attorney-carousel .owl-controls .owl-nav .owl-prev {
  left: 0px;
}
.attorney-carousel .owl-controls .owl-nav .owl-next {
  display: inline-table;
  left: 35px;
}
.attorney-carousel .owl-controls .owl-nav .owl-prev i,
.attorney-carousel .owl-controls .owl-nav .owl-next i {
  color: #fff;
  font-size: 30px;
  padding: 0;
  vertical-align: middle;
}
/* -------- Owl Carousel ---------- */
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-controls {
  margin: 0;
}
.owl-controls .owl-nav > div {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
  border-radius: 0;
  color: #fff;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40%;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  z-index: 6;
}
.owl-controls .owl-nav .owl-prev {
  left: -40px;
}
.owl-controls .owl-nav .owl-next {
  right: -40px;
}
.owl-controls .owl-nav .owl-prev i,
.owl-controls .owl-nav .owl-next i {
  color: #fff;
  font-size: 24px;
  padding: 5px 10px;
}
.owl-controls .owl-dots .owl-dot {
  display: inline-block;
  margin-top: 15px;
  zoom: 1;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-controls .owl-dots .owl-dot span {
  width: 25px;
  height: 7px;
  margin: 5px;
  opacity: 0.25;
  background: #222222;
  display: block;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  border: 1px solid transparent;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  opacity: 0.8;
}
.dot-center .owl-controls {
  text-align: center;
}
.owl-theme.dot-white .owl-controls .owl-dot span {
  background-color: #fff;
}
.dot-white .owl-theme .owl-controls .owl-dot span {
  background-color: #fff;
}
.recent-project .owl-controls .owl-nav > div {
  top: 38%;
}
.recent-project .owl-controls .owl-nav .owl-prev i,
.recent-project .owl-controls .owl-nav .owl-next i {
  padding: 2px 16px;
}
.featured-project-carousel .owl-controls .owl-nav .owl-prev i,
.featured-project-carousel .owl-controls .owl-nav .owl-next i {
  font-size: 40px;
  padding: 15px 18px;
}
.featured-gallery.style2 {
  float: right;
  width: 92%;
}
.featured-gallery.style2 .owl-controls {
  bottom: 58px;
  left: -80px;
  margin: 0;
  position: absolute;
}
.featured-gallery.style2 .owl-controls .owl-nav > div {
  top: 95%;
}
.featured-gallery.style2 .owl-controls .owl-nav .owl-prev {
  left: 0px;
}
.featured-gallery.style2 .owl-controls .owl-nav .owl-next {
  display: inline-table;
  left: 40px;
}
.featured-gallery.style2 .owl-controls .owl-nav .owl-prev i,
.featured-gallery.style2 .owl-controls .owl-nav .owl-next i {
  color: #fff;
  font-size: 35px;
}
@media only screen and (max-width: 991px) {
  .featured-gallery.style2 {
    float: none;
    margin-top: 58px;
    width: 100%;
  }
  .featured-gallery.style2 .owl-controls {
    bottom: auto;
    left: auto;
    top: -45px;
  }
}
.owl-carousel:hover .owl-controls .owl-nav .owl-prev {
  left: 0px;
}
.owl-carousel:hover .owl-controls .owl-nav .owl-next {
  right: 0px;
}
.image-carousel .owl-controls .owl-nav > div {
  opacity: 0;
}
.image-carousel:hover .owl-controls .owl-nav > div {
  opacity: 1;
}
/* -------- Owl Fullwidth Carousel ---------- */
.fullwidth-carousel .owl-controls .owl-nav > div {
  top: 45%;
  padding: 15px;
}
.fullwidth-carousel .owl-controls .owl-nav .owl-prev i,
.fullwidth-carousel .owl-controls .owl-nav .owl-next i {
  font-size: 50px;
}
.fullwidth-carousel .carousel-item {
  min-height: 720px;
  width: 100%;
}
.fullwidth-carousel.blog-fullwidth-carousel .carousel-item {
  height: 600px;
  min-height: auto;
  width: 100%;
}
.fullwidth-carousel.blog-fullwidth-carousel .owl-controls .owl-nav > div {
  background: none;
}
/* -------- Twitter Feed Carousel ---------- */
.twitter-feed.twitter-carousel {
  color: #111111;
  font-size: 16px;
}
.twitter-feed.twitter-carousel a {
  color: #111111;
}
.twitter-feed .date {
  font-size: 12px;
}
.twitter-feed.twitter-white,
.twitter-feed.twitter-white a {
  color: #fff;
}
/* -------- Typed Animation Slider ---------- */
.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
 * Home.less
 * -----------------------------------------------
*/
/* -------- Setion-Bottom-Absolute-Div ---------- */
.setion-bottom-absolute-div {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
/*
 * About.less
 * -----------------------------------------------
*/
/* -------- Bg Img Box ---------- */
.bg-img-box {
  position: relative;
}
.bg-img-box .caption {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  position: absolute;
  top: 0;
}
.bg-img-box .caption .title {
  font-size: 30px;
  line-height: 39px;
}
.bg-img-box .caption p {
  max-width: 280px;
}
/*
 * Contact.less
 * -----------------------------------------------
*/
.contact-wrapper {
  padding: 50px 50px 50px 100px;
}
.contact-form .form-group {
  margin-bottom: 20px;
}
.contact-form .form-control {
  background-image: none;
  border: 1px solid #c0c0c0;
  border-radius: 0;
  box-shadow: none;
  height: 48px;
}
.contact-form .form-control:focus,
.contact-form .form-control:active {
  box-shadow: none;
  color: #333333;
}
.contact-form .form-control2 {
  background-color: rgba(0, 0, 0, 0.8);
  color: #c0c0c0;
}
.contact-form .form-group button {
  margin-top: 0;
}
.contact-form textarea.form-control {
  height: 110px;
  resize: none;
}
textarea {
  resize: none;
}
/*
 * Event.less
 * -----------------------------------------------
*/
/* -------- Schedule-Box Style ---------- */
.schedule-box .thumb {
  position: relative;
  overflow-x: hidden;
}
.schedule-box .thumb .overlay {
  background-color: rgba(255, 255, 255, 0.85);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  -webkit-transition: 0.3s ease-in-out 0s;
  -o-transition: 0.3s ease-in-out 0s;
  transition: 0.3s ease-in-out 0s;
  top: -100%;
}
.schedule-box .thumb .overlay a i {
  background-color: #333;
  color: #fff;
  margin: 0 auto;
  padding: 13px 12px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.45s ease-in-out 0s;
  -o-transition: all 0.45s ease-in-out 0s;
  transition: all 0.45s ease-in-out 0s;
  top: 0;
}
.schedule-box .schedule-details {
  border-bottom: 1px solid #bbb;
}
.schedule-box .schedule-details ul li {
  margin: 0;
}
.schedule-box .schedule-details .title a {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.schedule-box:hover .thumb .overlay {
  opacity: 1;
  top: 0;
}
.schedule-box:hover .thumb .overlay a i {
  opacity: 1;
  top: 40%;
}
/* -------- Event-Table ---------- */
.table-schedule thead tr th {
  color: #fff;
  font-size: 16px;
  padding: 15px 25px;
  text-transform: uppercase;
}
.table-schedule > tbody > tr {
  background-color: #f3f3f3;
}
.table-schedule > tbody > tr:nth-child(2n+1) {
  background-color: #f9f9f9;
}
.table-schedule tbody tr td {
  border-top: 1px solid #f3f3f3;
  color: #666;
  font-weight: 400;
  padding: 15px 25px;
}
.table-schedule tbody tr td .speaker-thumb {
  float: left;
  margin-right: 15px;
  margin-top: 5px;
  width: 55px;
}
.table-schedule tbody tr td .title {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 3px;
}
.table-schedule tbody tr td .name {
  font-size: 13px;
  margin: 0;
}
.table-schedule tbody tr td strong {
  color: #666;
  font-weight: 700;
}
.table-schedule .toggle-content {
  cursor: pointer;
}
.table-schedule .session-details {
  display: none;
}
/*
 * Practice-Area.less
 * -----------------------------------------------
*/
.practice-area {
  overflow: hidden;
  position: relative;
}
.practice-area:hover .practice-name {
  opacity: 1;
  right: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/*
 * Services.less
 * -----------------------------------------------
*/
.rotated-service-box {
  position: relative;
}
.rotated-service-box .icon {
  background-color: #d3d3d3;
  display: inline-block;
  height: 90px;
  left: 0;
  margin: 0 auto;
  padding: 30px;
  position: absolute;
  right: 0;
  top: -45px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 90px;
}
.rotated-service-box .icon i {
  color: #fff;
  font-size: 32px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.rotated-service-box .heading {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/*
 * Job.less
 * -----------------------------------------------
*/
.job-overview {
  box-shadow: 0 0 0 2px #eee;
  padding: 20px 0 20px 20px;
}
.job-overview .dl-horizontal dt {
  width: auto;
}
.job-overview .dl-horizontal dd {
  margin-left: 40px;
}
/*
 * Shop.less
 * -----------------------------------------------
*/
/* -------- Product Details Page ---------- */
.product {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  position: relative;
}
.products .product {
  border: 1px solid #eee;
  max-width: 400px;
}
.products .product:hover {
  border: 1px solid #eee;
}
.btn-add-to-cart-wrapper {
  opacity: 0.9;
  height: auto;
  margin: 0 0 15px;
  text-align: center;
  width: 100%;
  background: #ffffff;
  -webkit-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
}
/* -------- Price ---------- */
.product .price {
  color: #333038;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0 15px;
}
.product .price del {
  color: #9e9d9e !important;
  font-size: 13px;
  margin-right: 5px;
}
.product .price ins {
  color: #333333;
  font-size: 16px;
  text-decoration: none;
}
.product .price ins,
.product .price ins {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  font-weight: 600;
}
/* -------- Product-Summary ---------- */
.product .product-summary .product-title {
  margin-top: 0;
}
.product .product-summary .short-description {
  margin-bottom: 15px;
}
.product .product-summary .cart-form-wrapper .btn {
  margin-top: 0;
}
.product .product-summary .variations {
  width: 100%;
}
.product .product-tab {
  margin-top: 30px;
}
.product .commentlist .comment {
  margin-top: 30px;
  position: relative;
}
.product .commentlist .comment .meta {
  font-size: 12px;
}
.product .commentlist .comment .author {
  font-size: 14px;
  font-weight: bold;
}
.product .product-thumb {
  position: relative;
}
.product .product-thumb:hover .overlay {
  opacity: 1;
}
.product .product-thumb .overlay .add-to-wishlist {
  color: #fff;
  right: 10px;
  top: 10px;
  position: absolute;
}
.product .product-summary .cart-form-wrapper .quantity,
.tbl-shopping-cart .quantity {
  display: inline-block;
  margin-right: .8571em;
  position: relative;
  width: 4.1429em;
  vertical-align: top;
}
.product .product-summary .cart-form-wrapper .quantity .minus,
.tbl-shopping-cart .quantity .minus,
.product .product-summary .cart-form-wrapper .quantity .plus,
.tbl-shopping-cart .quantity .plus {
  background: none repeat scroll 0 0 transparent;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  height: 18px;
  left: 2.8571em;
  line-height: 1;
  outline: medium none;
  padding: 0;
  position: absolute;
  width: 18px;
}
.product .product-summary .cart-form-wrapper .quantity .minus,
.tbl-shopping-cart .quantity .minus {
  bottom: 0;
}
.product .product-summary .cart-form-wrapper .quantity .plus,
.tbl-shopping-cart .quantity .plus {
  top: 0;
}
.product .product-summary .cart-form-wrapper .quantity .qty,
.tbl-shopping-cart .quantity .qty {
  -moz-appearance: textfield;
  border: 1px solid #ccc;
  height: 41px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 40px;
}
/* -------- Star-Rating ---------- */
.star-rating {
  font-size: 14px;
  height: 16px;
  line-height: 1;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: 5px;
  width: 75px;
}
.star-rating::before,
.star-rating span::before {
  color: #a9a9a9;
  content: "";
  float: left;
  font-family: fontawesome;
  left: 0;
  letter-spacing: 2px;
  position: absolute;
  top: 0;
}
.star-rating span {
  float: left;
  left: 0;
  overflow: hidden;
  padding-top: 1.5em;
  position: absolute;
  top: 0;
}
/* -------- Tag Sale ---------- */
.product .tag-sale {
  margin: 0;
  top: 0;
  display: block;
  left: auto;
  right: 0;
  -webkit-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 4px 10px 4px 16px;
  position: absolute;
  text-align: right;
  text-transform: uppercase;
  z-index: 9;
}
.product .tag-sale .tag-sale::before {
  border-color: transparent transparent transparent #ddd;
  border-style: solid;
  border-width: 12px 13px 13px 8px;
  content: "";
  height: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 1px;
  width: 0;
}
/* -------- Cart Page ---------- */
.tbl-shopping-cart .product-thumbnail img {
  max-width: 72px;
  max-height: 72px;
}
.tbl-shopping-cart .product-remove {
  text-align: center;
}
.tbl-shopping-cart .variation .variation-size,
.tbl-shopping-cart .variation .variation-size span {
  font-size: 12px;
}
.tbl-shopping-cart tr th,
.tbl-shopping-cart tr td,
.tbl-shopping-cart .coupon label,
.tbl-shopping-cart .coupon input,
.tbl-shopping-cart .coupon button {
  vertical-align: middle!important;
}
.tbl-shopping-cart tr td {
  padding: 10px 12px!important;
}
table.no-border td {
  border: medium none !important;
}
/* -------- Checkout Page ---------- */
.payment-method .radio {
  border: 1px solid #eee;
  padding: 10px;
}
.payment-method .radio > label {
  color: #111;
  font-size: 17px;
}
.payment-method .radio input {
  margin-top: 8px;
  vertical-align: initial;
}
.payment-method .radio > p {
  margin-left: 20px;
}
#checkout-shipping-address {
  display: none;
}
/* -------- Top Nav Search And Cart ---------- */
.top-nav-search-box .indicator,
.top-nav-cart-box .indicator {
  display: none;
}
.dropdown-cart {
  padding: 10px;
}
.dropdown-cart .cart-table-list,
.dropdown-cart .cart-table-list i {
  color: #222;
  border: none;
}
.dropdown-cart .cart-table-list a {
  line-height: normal;
  color: #222;
}
.dropdown-cart .cart-table-list td img {
  width: 50px;
  height: auto;
}
.dropdown-cart .cart-table-list.table > tbody > tr > td {
  border: none;
  line-height: 20px;
  height: 20px;
  vertical-align: middle;
}
.dropdown-cart .total-cart {
  display: inline-block;
  width: 100%;
  padding-top: 20px;
  color: #222;
  text-align: right;
}
.dropdown-cart .total-cart ul {
  float: right;
  list-style: none;
  padding: 0;
  width: 100%;
}
.dropdown-cart .total-cart ul li {
  display: block;
  overflow: hidden;
}
.dropdown-cart .total-cart ul li span {
  width: 100px;
  display: inline-block;
  text-align: right;
  white-space: nowrap;
}
.dropdown-cart .total-cart .table > tbody > tr > td,
.dropdown-cart .total-cart .table > tbody > tr > th,
.dropdown-cart .total-cart .table > tfoot > tr > td,
.dropdown-cart .total-cart .table > tfoot > tr > th,
.dropdown-cart .total-cart .table > thead > tr > td,
.dropdown-cart .total-cart .table > thead > tr > th {
  border: 0;
}
.dropdown-cart .cart-btn .btn {
  width: auto;
  float: none;
  padding: 5px 15px;
}
/*
 * Blog.less
 * -----------------------------------------------
*/
.blog-posts .post {
  position: relative;
}
.blog-posts .post .entry-title {
  padding-top: 14px;
  text-transform: uppercase;
}
.blog-posts .post .post-thumb {
  overflow: hidden;
  position: relative;
}
.blog-posts .post .entry-content {
  position: relative;
}
.blog-posts .post .entry-content .post-date,
.blog-posts .post .entry-content .post-visited {
  color: #a9a9a9;
  font-size: 13px;
}
.blog-posts .post .entry-content .post-date.right {
  top: -10px;
  height: 60px;
  padding: 6px 8px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 60px;
}
.blog-posts .post .entry-content .post-date {
  background: #222222 none repeat scroll 0 0;
  display: inline-block;
  padding: 0px 14px;
  top: 0;
}
.blog-posts .post .entry-content ul li {
  margin-bottom: 0;
  margin-top: 0;
}
.blog-posts .post .entry-meta span i {
  margin-right: 5px;
}
.blog-posts .post .entry-meta span {
  font-size: 12px;
  margin-right: 15px;
}
.blog-posts .post .post-date p,
.blog-posts .post .post-date a {
  font-size: 14px;
  color: #a9a9a9;
}
.blog-posts .post .post-date a:hover {
  color: #333333;
}
.blog-posts .post .like-comment li {
  margin-bottom: 0;
  margin-top: 0;
}
.blog-posts .post .post-thumb .owl-dots {
  bottom: 0;
  position: absolute;
  right: 10px;
}
.blog-posts .post .post-thumb .owl-dots .owl-dot span {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
.like-comment li i {
  font-size: 20px;
  margin-right: 3px;
  vertical-align: middle;
}
.like-comment.small-font li i {
  font-size: 16px;
}
.blog-pull-right {
  float: right;
}
.media-post .post-thumb {
  float: left;
  margin: 0 10px 10px 0;
}
.media-post .post-right {
  overflow: hidden;
}
.media-post .post-title a {
  font-size: 13px;
  letter-spacing: 1px;
}
@media only screen and (max-width: 991px) {
  .blog-posts .post {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-pull-right {
    float: none;
  }
}
/* -------- Blog Single ---------- */
.single-post article {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 20px;
  padding-bottom: 30px;
}
.single-post article .entry-title {
  font-family: "Open Sans", sans-serif;
  padding: 15px 0 0;
  text-transform: capitalize;
}
.single-post .entry-content {
  border: none;
}
.single-post .author-details {
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  margin: 20px 0;
  padding: 20px 0;
}
.single-post .post-share ul {
  margin-bottom: 15px;
}
.single-post .post-share ul li {
  display: inline-block;
  margin-right: 5px;
}
.single-post .post-share ul li a {
  background: none repeat scroll 0 0 #eeeeee;
  border: 1px solid #eeeeee;
  border-radius: 0;
}
.single-post .post-share ul li a i {
  color: #555555;
}
.single-post .post-share ul li a:hover i {
  color: #111111;
}
.single-post .media-heading {
  margin-bottom: 5px;
  margin-top: 0;
}
.single-post .comments-title {
  margin-bottom: 15px;
}
.single-post .comment-list {
  padding-left: 0;
}
.single-post .comment-list h6 {
  font-family: helvetica;
}
.single-post .comment-list li {
  list-style: none;
}
.single-post .comment-list .comment-author {
  margin-top: 30px;
}
.single-post .comment-list .comment-date,
.single-post .comment-list .comment-heading {
  font-size: 13px;
}
.single-post .reply-box h4 {
  margin-bottom: 15px;
}
.single-post .reply-box .form-group input {
  border-radius: 0;
  height: 45px;
  margin-bottom: 28px;
}
.single-post .reply-box .form-group textarea {
  border-radius: 0;
}
.single-post .reply-box #comment-form button.btn {
  margin-right: 0;
}
.single-post .reply-box .btn-primary {
  margin-right: 0;
}
.single-post .reply-box .tagline {
  border-top: 1px solid #d3d3d3;
}
.reply-box .form-group input {
  border-radius: 0;
  height: 45px;
  margin-bottom: 30px;
}
/*
 * Shortcode.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Alerts.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Buttons.less
 * -----------------------------------------------
*/
/* -------- Btn-Default ---------- */
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:focus,
.btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}
.btn-default .badge {
  color: #fff;
  background-color: #333;
}
.btn-default {
  text-transform: uppercase;
}
.btn-default:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
}
/* -------- Btn-Border ---------- */
.btn-border {
  color: #222222;
  background-color: #fff;
  border-color: #222222;
}
.btn-border:focus,
.btn-border.focus {
  color: #222222;
  background-color: #e6e6e6;
  border-color: #000000;
}
.btn-border:hover {
  color: #222222;
  background-color: #e6e6e6;
  border-color: #040404;
}
.btn-border:active,
.btn-border.active,
.open > .dropdown-toggle.btn-border {
  color: #222222;
  background-color: #e6e6e6;
  border-color: #040404;
}
.btn-border:active:hover,
.btn-border.active:hover,
.open > .dropdown-toggle.btn-border:hover,
.btn-border:active:focus,
.btn-border.active:focus,
.open > .dropdown-toggle.btn-border:focus,
.btn-border:active.focus,
.btn-border.active.focus,
.open > .dropdown-toggle.btn-border.focus {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000;
}
.btn-border:active,
.btn-border.active,
.open > .dropdown-toggle.btn-border {
  background-image: none;
}
.btn-border.disabled:hover,
.btn-border[disabled]:hover,
fieldset[disabled] .btn-border:hover,
.btn-border.disabled:focus,
.btn-border[disabled]:focus,
fieldset[disabled] .btn-border:focus,
.btn-border.disabled.focus,
.btn-border[disabled].focus,
fieldset[disabled] .btn-border.focus {
  background-color: #fff;
  border-color: #222222;
}
.btn-border .badge {
  color: #fff;
  background-color: #222222;
}
.btn-border {
  text-transform: uppercase;
}
.btn-border {
  border-width: 2px;
}
.btn-border:hover,
.btn-border:active,
.btn-border:focus {
  background-color: #222222;
  border-color: #222222;
  color: #fff;
}
/* -------- Btn-Dark ---------- */
.btn-dark {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #090909;
  border-color: #000000;
}
.btn-dark:hover {
  color: #fff;
  background-color: #090909;
  border-color: #040404;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  color: #fff;
  background-color: #090909;
  border-color: #040404;
}
.btn-dark:active:hover,
.btn-dark.active:hover,
.open > .dropdown-toggle.btn-dark:hover,
.btn-dark:active:focus,
.btn-dark.active:focus,
.open > .dropdown-toggle.btn-dark:focus,
.btn-dark:active.focus,
.btn-dark.active.focus,
.open > .dropdown-toggle.btn-dark.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  background-image: none;
}
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled.focus,
.btn-dark[disabled].focus,
fieldset[disabled] .btn-dark.focus {
  background-color: #222222;
  border-color: #222222;
}
.btn-dark .badge {
  color: #222222;
  background-color: #fff;
}
.btn-dark {
  text-transform: uppercase;
}
/* -------- Btn-Gray ---------- */
.btn-gray {
  color: #333333;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-gray:focus,
.btn-gray.focus {
  color: #333333;
  background-color: #d5d5d5;
  border-color: #afafaf;
}
.btn-gray:hover {
  color: #333333;
  background-color: #d5d5d5;
  border-color: #d0d0d0;
}
.btn-gray:active,
.btn-gray.active,
.open > .dropdown-toggle.btn-gray {
  color: #333333;
  background-color: #d5d5d5;
  border-color: #d0d0d0;
}
.btn-gray:active:hover,
.btn-gray.active:hover,
.open > .dropdown-toggle.btn-gray:hover,
.btn-gray:active:focus,
.btn-gray.active:focus,
.open > .dropdown-toggle.btn-gray:focus,
.btn-gray:active.focus,
.btn-gray.active.focus,
.open > .dropdown-toggle.btn-gray.focus {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf;
}
.btn-gray:active,
.btn-gray.active,
.open > .dropdown-toggle.btn-gray {
  background-image: none;
}
.btn-gray.disabled:hover,
.btn-gray[disabled]:hover,
fieldset[disabled] .btn-gray:hover,
.btn-gray.disabled:focus,
.btn-gray[disabled]:focus,
fieldset[disabled] .btn-gray:focus,
.btn-gray.disabled.focus,
.btn-gray[disabled].focus,
fieldset[disabled] .btn-gray.focus {
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-gray .badge {
  color: #eeeeee;
  background-color: #333333;
}
.btn-gray {
  text-transform: uppercase;
}
.btn-circled {
  border-radius: 25px;
}
.btn-flat {
  border-radius: 0;
}
/* -------- Btn-Default ---------- */
.btn {
  font-size: 13px;
  padding: 8px 22px;
}
/* -------- Btn-xs ---------- */
.btn-xs {
  font-size: 11px;
  padding: 3px 14px;
}
/* -------- Btn-sm ---------- */
.btn-sm {
  font-size: 12px;
  padding: 5px 17px;
}
/* -------- Btn-lg ---------- */
.btn-lg {
  font-size: 14px;
  padding: 10px 26px;
}
/* -------- Btn-xl ---------- */
.btn-xl {
  font-size: 16px;
  padding: 13px 32px;
}
/* -------- Btn-transparent ---------- */
.btn-transparent {
  background-color: transparent;
  color: #fff;
}
/*
 * Blockquote.less
 * -----------------------------------------------
*/
blockquote {
  border-left: 3px solid #eeeeee;
  margin: 20px 0;
  padding: 30px;
}
blockquote.gray {
  border-left: 3px solid #666666;
  background-color: #eeeeee;
  color: #333333;
}
blockquote.gray.bg-theme-colored {
  background-color: #eeeeee;
}
blockquote.dark {
  border-left: 3px solid #3c3c3c;
  background-color: #555555;
  color: #fff;
}
blockquote.dark footer {
  color: #d3d3d3;
}
blockquote p {
  font-family: "Playfair Display", serif;
  font-size: 16px;
  font-style: italic;
}
/*
 * Shortcode: Clients.less
 * -----------------------------------------------
*/
.clients-logo .item {
  border-color: transparent;
  box-shadow: 0 0 0 10px #ffffff;
  margin-bottom: 30px;
  padding: 15px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.clients-logo img {
  max-height: 90px;
  width: auto !important;
  display: inline;
  opacity: 0.6;
}
.clients-logo img:hover {
  opacity: 1;
}
.clients-logo.style2 .item {
  box-shadow: none;
}
.clients-logo.style2 .item img {
  max-height: 120px;
}
.client-img {
  opacity: 0.6;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.client-img:hover {
  opacity: 1;
}
.client-img img {
  max-height: 90px;
}
.clients-logo.carousel {
  text-align: center;
}
.clients-logo.carousel .item {
  box-shadow: none;
  margin: 0 10px;
}
/*
 * Shortcode: Countdown.less
 * -----------------------------------------------
*/
.countdown-timer li {
  color: #808080;
  display: inline-block;
  padding: 30px 20px;
  background-color: #f1f1f1;
  margin-right: 15px;
  font-size: 40px;
  text-align: center;
  min-width: 120px;
  min-height: 120px;
}
.countdown-timer li span {
  color: #c0c0c0;
  display: block;
  font-size: 15px;
  margin-top: 5px;
}
.countdown-timer.timer-box {
  padding: 20px;
  padding-top: 40px;
  text-align: center;
}
.countdown-timer.timer-box li {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}
.countdown-timer.timer-box li span {
  color: #eeeeee;
}
.countdown-timer.timer-deep-bg li {
  background-color: rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 767px) {
  .timer-responsive li {
    margin-top: 30px;
    font-size: 20px;
    min-width: 60px;
    min-height: 60px;
    padding: 20px 10px;
  }
  .countdown-timer li span {
    font-size: 12px;
  }
}
/*
 * Shortcode: Countdown-Timer.less
 * -----------------------------------------------
*/
/* -------- My-Soon-Watch-Red ---------- */
#countdown-timer-soon-watch-red {
  background-color: #030303;
  color: #ffffff;
  font-family: "Comfortaa", sans-serif;
}
#countdown-timer-soon-watch-red .soon-reflection {
  background-color: #030303;
  background-image: linear-gradient(#030303 25%, rgba(3, 3, 3, 0));
}
#countdown-timer-soon-watch-red .soon-label {
  color: #ffffff;
  color: rgba(255, 255, 255, 0.75);
}
#countdown-timer-soon-watch-red .soon-ring-progress {
  background-color: #410918;
  border-top-width: 14px;
  border-bottom-width: 13px;
}
/* -------- Soon-Scifi ---------- */
#countdown-timer-soon-scifi {
  color: #313857;
  text-transform: lowercase;
}
#countdown-timer-soon-scifi .soon-reflection {
  background-color: #090e14;
  background-image: linear-gradient(#090e14 0, rgba(9, 14, 20, 0));
}
#countdown-timer-soon-scifi .soon-matrix-dot {
  background-color: transparent;
}
#countdown-timer-soon-scifi .soon-matrix-dot:nth-child(1)[data-state='1'] {
  background-color: #3559ff;
}
#countdown-timer-soon-scifi .soon-matrix-dot:nth-child(2)[data-state='1'] {
  background-color: #1c46e7;
}
#countdown-timer-soon-scifi .soon-matrix-dot:nth-child(3)[data-state='1'] {
  background-color: #1738ba;
}
#countdown-timer-soon-scifi .soon-matrix-dot:nth-child(4)[data-state='1'] {
  background-color: #0f257b;
}
#countdown-timer-soon-scifi .soon-matrix-dot:nth-child(5)[data-state='1'] {
  background-color: #0a1854;
}
/* -------- My-Soon-Orange ---------- */
#countdown-timer-soon-orange {
  background-color: #ffffff;
  color: #141541;
  font-family: "Source Sans Pro", sans-serif;
}
#countdown-timer-soon-orange .soon-reflection {
  background-color: #ffffff;
  background-image: linear-gradient(#ffffff 25%, rgba(255, 255, 255, 0));
}
#countdown-timer-soon-orange .soon-label {
  color: #fb801b;
}
#countdown-timer-soon-orange .soon-ring-progress {
  background-color: #f1f1f1;
  border-top-width: 4px;
  border-bottom-width: 2px;
}
/* -------- Soon-Product ---------- */
#countdown-timer-soon-product {
  position: relative;
  text-align: left;
  font-family: 'Chewy', sans-serif;
  color: #fff;
  padding: .5em 1em 5em 1em;
  background-color: #000;
  background-image: url('images/countdown/shop.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  text-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.25);
}
#countdown-timer-soon-product .soon-placeholder {
  display: block;
  color: #00CCFF;
  font-weight: bold;
}
#countdown-timer-soon-product .soon-label,
#countdown-timer-soon-product .soon-valu {
  font-size: 1.5em;
}
/* -------- Soon-Rev ---------- */
#countdown-timer-soon-rev {
  font-family: 'Josefin Slab', serif;
  color: #31305b;
}
#countdown-timer-soon-rev .soon-label {
  color: #823953;
}
/* -------- Soon-Prog ---------- */
#countdown-timer-soon-prog .soon-label {
  text-transform: lowercase;
  color: #424242;
}
#countdown-timer-soon-prog .soon-value {
  color: #424242;
}
#countdown-timer-soon-prog .soon-fill-inner {
  background-color: #E9E9E9;
}
#countdown-timer-soon-prog .soon-fill-progress {
  background-color: #fafafa;
}
/* -------- Soon-Cartoon ---------- */
#countdown-timer-soon-cartoon {
  font-family: 'Port Lligat Slab', serif;
  color: #F5ECB7;
}
#countdown-timer-soon-cartoon .soon-label {
  color: #F57576;
}
/* -------- Soon-Klas ---------- */
#countdown-timer-soon-klas {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  text-shadow: 0 0 0.125em rgba(255, 255, 255, 0.5);
}
#countdown-timer-soon-klas .soon-placeholder {
  font-size: 2em;
}
#countdown-timer-soon-klas .soon-label {
  font-weight: bold;
  text-align: left;
  margin-left: .5em;
  margin-top: -1.5em;
  font-size: .2em;
}
/* -------- Soon-Radi ---------- */
#countdown-timer-soon-radi .soon-label {
  color: #ffbe00;
}
#countdown-timer-soon-radi .soon-matrix-row:nth-child(1) .soon-matrix-dot[data-state='1'] {
  background-color: red;
}
#countdown-timer-soon-radi .soon-matrix-row:nth-child(2) .soon-matrix-dot[data-state='1'] {
  background-color: #ff1900;
}
#countdown-timer-soon-radi .soon-matrix-row:nth-child(3) .soon-matrix-dot[data-state='1'] {
  background-color: #ff4400;
}
#countdown-timer-soon-radi .soon-matrix-row:nth-child(4) .soon-matrix-dot[data-state='1'] {
  background-color: #ff6700;
}
#countdown-timer-soon-radi .soon-matrix-row:nth-child(5) .soon-matrix-dot[data-state='1'] {
  background-color: #ff8300;
}
#countdown-timer-soon-radi .soon-matrix-row:nth-child(6) .soon-matrix-dot[data-state='1'] {
  background-color: #ff9a00;
}
#countdown-timer-soon-radi .soon-matrix-row:nth-child(7) .soon-matrix-dot[data-state='1'] {
  background-color: #ffbe00;
}
/* -------- Soon-Sep ---------- */
#countdown-timer-soon-sep {
  font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", sans-serif;
  text-align: center;
  color: #1E99DD;
}
#countdown-timer-soon-sep .soon-placeholder {
  color: #fff;
  display: block;
}
#countdown-timer-soon-sep .soon-lbl {
  font-size: .75em;
}
#countdown-timer-soon-sep .soon-reflection {
  background-image: linear-gradient(#5fc6fd 25%, rgba(95, 198, 253, 0));
  opacity: .4;
}
#countdown-timer-soon-sep .soon-tagline {
  color: #1E99DD;
}
/* -------- Soon-Glow ---------- */
#countdown-timer-soon-glow {
  font-family: 'Quicksand', sans-serif;
  color: #fff;
  text-transform: lowercase;
}
#countdown-timer-soon-glow .soon-label {
  color: #fff;
  text-shadow: 0 0 0.25rem rgba(255, 255, 255, 0.75);
}
#countdown-timer-soon-glow .soon-ring-progress {
  color: #fff;
}
/* -------- Soon-Espa ---------- */
#countdown-timer-soon-espa .soon-label {
  color: #605951;
}
#countdown-timer-soon-espa .soon-reflection {
  opacity: .1;
  background-color: #dedede;
  background-image: linear-gradient(#dedede 25%, rgba(222, 222, 222, 0));
}
/*
 * Shortcode: Dividers.less
 * -----------------------------------------------
*/
.divider,
.parallax {
  background-repeat: repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
/* -------- Parallax ---------- */
.parallax {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
}
.mobile-parallax {
  background-size: cover !important;
  background-attachment: scroll !important;
  background-position: center center;
}
.divider .container {
  padding-top: 60px;
  padding-bottom: 60px;
}
.divider .container.small-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}
.divider .container.extra-padding {
  padding-top: 180px;
  padding-bottom: 180px;
}
/*
 * Shortcode: Facebook-Disqus-Comments.less
 * -----------------------------------------------
*/
.fb-comments,
.fb_iframe_widget,
.fb-comments > span,
.fb_iframe_widget > span,
.fb-comments > span > iframe,
.fb_iframe_widget > span > iframe {
  display: block !important;
  margin: 0;
  width: 100% !important;
}
/*
 * Shortcode: Faqs.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Flexslider.less
 * -----------------------------------------------
*/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
  border: none;
}
.flex-direction-nav {
  display: none;
}
.flexslider {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.flex-control-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  width: auto;
  position: absolute;
  z-index: 10;
  text-align: center;
  bottom: 15px;
  right: 15px;
}
.flex-control-nav li {
  float: left;
  display: block;
  margin: 0 3px;
  width: 10px;
  height: 10px;
}
.flex-control-nav li a {
  display: block;
  cursor: pointer;
  text-indent: -9999px;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.flex-control-nav li:hover a,
.flex-control-nav li a.flex-active {
  background-color: #fff;
}
/*
 * Shortcode: Flip-Box.less
 * -----------------------------------------------
*/
.card {
  float: left;
  min-height: 300px;
  padding-bottom: 25%;
  position: relative;
  text-align: center;
  width: 100%;
}
.card:nth-child(1) {
  margin-left: -3px;
  margin-right: 1px;
}
.card:nth-child(1),
.card .card:nth-child(3) {
  margin-right: 1px;
}
.card .card__front,
.card .card__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
}
.card .card__back {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card .card__text {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 300px;
  line-height: 20px;
}
.card.effect__hover:hover .card__front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card.effect__hover:hover .card__back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
.card.effect__click.flipped .card__front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card.effect__click.flipped .card__back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
.card.effect__random.flipped .card__front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card.effect__random.flipped .card__back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
/*
 * Shortcode: Font-Icons-Style.less
 * -----------------------------------------------
*/
/* common style */
.social-icons a {
  color: #333333;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  width: 32px;
  float: left;
  margin: 5px 7px 5px 0;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.social-icons a:hover {
  color: #666666;
}
.social-icons.icon-gray a {
  background-color: #eeeeee;
  color: #555555;
  display: block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  width: 36px;
}
.social-icons.icon-gray a:hover {
  color: #bbbbbb;
}
.social-icons.icon-gray.icon-bordered a {
  background-color: transparent;
  border: 2px solid #eeeeee;
}
.social-icons.icon-gray.icon-bordered a:hover {
  border: 2px solid #d5d5d5;
}
.social-icons.icon-dark a {
  background-color: #111111;
  color: #fff;
  display: block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  width: 36px;
}
.social-icons.icon-dark a:hover {
  color: #5e5e5e;
}
.social-icons.icon-dark.icon-bordered a {
  background-color: transparent;
  border: 2px solid #111111;
  color: #111111;
}
.social-icons.icon-dark.icon-bordered a:hover {
  background-color: #111111;
  border-color: #111111;
  color: #fff;
}
.social-icons.icon-bordered a {
  border: 1px solid #777777;
}
.social-icons.icon-bordered a:hover {
  background-color: #777777;
  color: #fff;
}
.social-icons.icon-rounded a {
  border-radius: 3px;
}
.social-icons.icon-circled a {
  border-radius: 50%;
}
.social-icons.icon-sm a {
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  margin: 2px 7px 2px 0;
  width: 30px;
}
.social-icons.icon-md a {
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  width: 50px;
}
.social-icons.icon-lg a {
  font-size: 32px;
  height: 60px;
  line-height: 60px;
  width: 60px;
}
.social-icons.icon-xl a {
  font-size: 60px;
  height: 120px;
  line-height: 120px;
  width: 120px;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}
/*
 * Shortcode: Funfacts.less
 * -----------------------------------------------
*/
.funfact {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.funfact.border {
  border: 1px solid #d3d3d3;
}
.funfact.no-border {
  border: 0;
}
.funfact.border {
  border: 1px solid #d3d3d3;
}
.funfact i {
  font-size: 45px;
}
.funfact h2 {
  position: relative;
}
.funfact h2:after {
  bottom: 0;
}
.funfact span {
  color: #eeeeee;
  font-size: 18px;
}
/* funfact style-1*/
.funfact.style-1 i,
.funfact.style-1 h4 {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.funfact.style-1:hover i {
  font-size: 40px !important;
  opacity: 0.3;
}
.funfact.style-1:hover h4 {
  color: #000 !important;
}
/*
 * Shortcode: Icon-Boxes.less
 * -----------------------------------------------
*/
.icon-box {
  margin-bottom: 60px;
  padding: 40px 17px;
}
.icon-box .icon {
  display: inline-block;
  height: 64px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 64px;
}
.icon-box .icon i {
  line-height: 64px;
}
.icon-box .icon.icon-gray {
  background-color: #eeeeee;
  color: #555555;
}
.icon-box .icon.icon-gray:hover {
  background-color: #d5d5d5;
  color: #bbbbbb;
}
.icon-box .icon.icon-gray.icon-bordered {
  background-color: transparent;
  border: 2px solid #eeeeee;
}
.icon-box .icon.icon-gray.icon-bordered:hover {
  background-color: #eeeeee;
  color: #555555;
}
.icon-box .icon.icon-dark {
  background-color: #111111;
  color: #fff;
}
.icon-box .icon.icon-dark:hover {
  color: #5e5e5e;
}
.icon-box .icon.icon-dark.icon-bordered {
  background-color: transparent;
  border: 2px solid #111111;
  color: #111111;
}
.icon-box .icon.icon-dark.icon-bordered:hover {
  background-color: #111111;
  border-color: #111111;
  color: #fff;
}
.icon-box .icon.icon-white {
  background-color: #fff;
  color: #333333;
}
.icon-box .icon.icon-white:hover {
  background-color: #b3b3b3;
  color: #000000;
}
.icon-box .icon.icon-white.icon-bordered {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}
.icon-box .icon.icon-white.icon-bordered:hover {
  background-color: #fff;
  border-color: #fff;
  color: #111111;
}
.icon-box .icon.icon-white.icon-border-effect {
  position: relative;
}
.icon-box .icon.icon-white.icon-border-effect::after {
  box-shadow: 0 0 0 3px #fff;
}
.icon-box .icon.icon-bordered {
  border: 1px solid #777777;
}
.icon-box .icon.icon-bordered:hover {
  background-color: #777777;
  color: #fff;
}
.icon-box .icon.icon-rounded {
  border-radius: 3px;
}
.icon-box .icon.icon-circled {
  border-radius: 50%;
}
.icon-box .icon.icon-xs {
  height: 30px;
  width: 30px;
}
.icon-box .icon.icon-xs i {
  font-size: 18px;
  line-height: 30px;
}
.icon-box .icon.icon-sm {
  height: 50px;
  width: 50px;
}
.icon-box .icon.icon-sm i {
  font-size: 22px;
  line-height: 50px;
}
.icon-box .icon.icon-md {
  height: 75px;
  width: 75px;
}
.icon-box .icon.icon-md i {
  font-size: 36px;
  line-height: 75px;
}
.icon-box .icon.icon-lg {
  height: 90px;
  width: 90px;
}
.icon-box .icon.icon-lg i {
  font-size: 48px;
  line-height: 90px;
}
.icon-box .icon.icon-xl {
  height: 120px;
  width: 120px;
}
.icon-box .icon.icon-xl i {
  font-size: 60px;
  line-height: 120px;
}
.icon-box .icon.icon-border-effect {
  position: relative;
}
.icon-box .icon.icon-border-effect::after {
  border-radius: 50%;
  box-shadow: 0 0 0 3px #333333;
  box-sizing: content-box;
  content: "";
  height: 100%;
  left: -4px;
  opacity: 0;
  padding: 4px;
  top: -4px;
  transform: scale(0.8);
  transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.icon-box .icon.icon-border-effect.effect-flat::after {
  border-radius: 0;
}
.icon-box .icon.icon-border-effect.effect-rounded::after {
  border-radius: 3px;
}
.icon-box .icon.icon-border-effect.effect-circled::after {
  border-radius: 50%;
}
.icon-box .icon.icon-border-effect.icon-gray::after {
  box-shadow: 0 0 0 3px #eeeeee;
}
.icon-box .icon.icon-top {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: -32px;
}
.icon-box i {
  display: inline-block;
  font-size: 40px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.icon-box i[class*="pe-7s-"] {
  vertical-align: text-bottom;
}
.icon-box.left {
  text-align: left;
}
.icon-box.left a {
  margin-right: 30px;
  padding: 0;
}
.icon-box.iconbox-border {
  border: 1px solid #dcdcdc;
}
.icon-box.iconbox-bg {
  background-color: #fafafa;
}
.icon-box.iconbox-bg.iconbox-bg-dark {
  background-color: #333333;
}
.icon-box .icon-box-title {
  margin-bottom: 15px;
}
.icon-box .icon-border-effect:hover::after,
.icon-box:hover .icon-border-effect::after {
  opacity: 1;
  transform: scale(1);
}
/*
 * Shortcode: Infinitescroll.less
 * -----------------------------------------------
*/
#infscr-loading {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #fff;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  left: 48%;
  position: fixed;
  text-align: center;
  top: 50%;
  width: 50px;
  z-index: 100;
}
#infscr-loading img {
  display: none;
}
/*
 * Shortcode: Instagram.less
 * -----------------------------------------------
*/
/* -------- Instagram-Feed ---------- */
.instagram-feed-carousel figure,
.instagram-feed figure {
  position: relative;
}
.instagram-feed-carousel figure .link-out,
.instagram-feed figure .link-out {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.45);
  color: #f8f8f8;
  line-height: 1;
  padding: 10px;
  font-weight: 300;
  opacity: 0;
}
.instagram-feed-carousel figure .link-out:hover,
.instagram-feed figure .link-out:hover {
  background: rgba(0, 0, 0, 0.7);
  color: #f8f8f8;
}
.instagram-feed-carousel figure:hover .link-out,
.instagram-feed figure:hover .link-out {
  opacity: 1;
}
/* -------- Instagram-Feed ---------- */
.instagram-feed .item {
  width: 100px;
  float: left;
  margin-bottom: 2px;
  margin-right: 2px;
}
/*
 * Shortcode: Light-Box.less
 * -----------------------------------------------
*/
.lightbox .lb-dataContainer {
  left: 0;
  position: absolute;
  right: 0;
  top: -45px;
}
/*
 * Shortcode: Map.less
 * -----------------------------------------------
*/
.toggle-map {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  padding: 30px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  z-index: 200;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
.toggle-map:hover {
  color: #fff;
}
.toggle-map span:after {
  content: "\f107";
  font-family: fontawesome;
  margin-left: 10px;
}
.toggle-map.open span:after {
  content: "\f106";
}
.map-canvas {
  height: 400px;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.map-canvas.big {
  height: 720px;
}
.map-canvas.map-hidden {
  height: 0;
}
.btn-show-map {
  color: #111111;
  font-size: 18px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 45%;
}
.btn-show-map i {
  font-size: 48px;
}
/*
 * Shortcode: Pegination.less
 * -----------------------------------------------
*/
.pagination.theme-colored li.active a {
  background: #BF9E58;
  color: #fff;
  border: 1px solid transparent;
}
.pagination.dark li a {
  color: #333;
}
.pagination.dark li.active a {
  background: #333;
  color: #fff;
  border: 1px solid transparent;
}
.pager.theme-colored li.active a {
  color: #fff;
  border: 1px solid transparent;
}
.pager.dark li a {
  color: #fff;
  background-color: #333;
  border: 1px solid transparent;
}
/*
 * Shortcode: Pricing.less
 * -----------------------------------------------
*/
.pricing-table {
  margin-bottom: 30px;
  position: relative;
}
.pricing-table .table-price {
  color: #111111;
  font-size: 45px;
}
.pricing-table .table-price span {
  font-size: 13px;
  vertical-align: middle;
}
.pricing-table.featured {
  border: 1px solid #111111;
}
.pricing-table .table-type {
  display: inline;
}
.pricing-table .btn-signup {
  margin-right: 0;
  width: 100%;
}
.pricing-table .table-list {
  list-style: outside none none;
  padding: 10px 0 10px 20px;
}
.pricing-table .table-list li {
  border-bottom: 0;
  font-size: 12px;
  padding: 10px 0;
}
.pricing-table .table-list li i {
  margin-right: 8px;
}
.pricing-table .package-type span {
  position: relative;
  padding-left: 15px;
  margin-left: 10px;
}
.pricing-table .package-type span::after {
  background: #2d915b;
  content: "";
  height: 20px;
  left: -20px;
  position: absolute;
  top: 11px;
  width: 20px;
}
/*
 * Shortcode: Process-Steps.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Progress-Bar.less
 * -----------------------------------------------
*/
.progress-item {
  margin-top: 20px;
}
.progress-item .progress {
  background-color: #f8f8f8;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4) inset;
  border-radius: 0;
  height: 10px;
  margin-bottom: 15px;
  overflow: visible;
}
.progress-item .progress-bar {
  font-size: 11px;
  padding-left: 10px;
  position: relative;
  text-align: left;
  width: 0;
  line-height: 18px;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.progress-item .progress-bar .percent {
  background: none repeat scroll 0 0 #555555;
  border-radius: 8px 0 0;
  padding: 3px 5px;
  position: absolute;
  right: 0;
  top: -30px;
}
.progress-item.progress-white .progress {
  padding: 2px;
}
.progress-item.style2 .progress {
  height: 25px;
}
.progress-item.style2 .progress-bar .percent {
  background: none;
  color: #555555;
  font-size: 14px;
}
/*
 * Shortcode: Promo.less
 * -----------------------------------------------
*/
.modal-promo-box {
  max-width: 700px;
  padding: 90px 60px;
  margin: 0 auto;
}
/*
 * Shortcode: Section-Title.less
 * -----------------------------------------------
*/
.small-title {
  position: relative;
}
.small-title i {
  font-size: 36px;
  margin-top: 7px;
  padding-top: 0px;
}
.small-title .title {
  margin-left: 15px;
}
.small-title .title:after {
  bottom: -6px;
  content: "";
  height: 1px;
  left: 45px;
  position: absolute;
  width: 45px;
}
.section-title .both-side-line::after {
  content: "";
  height: 2px;
  position: absolute;
  right: -40px;
  top: 10px;
  width: 30px;
}
.section-title .both-side-line::before {
  content: "";
  height: 2px;
  position: absolute;
  left: -40px;
  top: 10px;
  width: 30px;
}
.section-title .top-side-line::after {
  content: "";
  height: 2px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: -8px;
  width: 40px;
}
.section-title .left-side-line::before {
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: -8px;
  width: 35px;
}
.section-title .right-side-line::before {
  content: "";
  height: 2px;
  position: absolute;
  right: 0;
  top: -8px;
  width: 35px;
}
.section-title .sub-title {
  margin: 0;
  position: relative;
  display: inline-block;
}
.section-title .title {
  margin-top: 0;
  position: relative;
}
.section-title p {
  font-size: 14px;
}
.section-title p.left-bordered {
  border-left: 2px solid #c0c0c0;
  padding-left: 10px;
}
.section-title p.right-bordered {
  border-right: 2px solid #c0c0c0;
  padding-right: 10px;
}
/*Theme Section Title*/
.section-title {
  margin-bottom: 30px;
}
.section-title.title-border {
  padding-left: 30px;
}
.section-title.title-border.title-right {
  border-left: 0;
  padding-left: 0;
  padding-right: 30px;
  text-align: right;
}
.section-title.icon-bg p {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 0;
}
.section-title.icon-bg .page-title {
  font-size: 38px;
  line-height: 52px;
}
.section-title.icon-bg .page-title i {
  font-size: 42px;
  line-height: 1.3;
  position: absolute;
  opacity: 0.15;
  left: 0;
  right: 0;
  margin: 0 auto;
}
/*
 * Shortcode: Separators.less
 * -----------------------------------------------
*/
.title-separator {
  border-top-style: solid;
  border-top-width: 1px;
  font-size: 16px;
  height: auto;
  line-height: 20px;
  margin: 10px 0;
  width: 70%;
}
.title-separator span {
  background: #fff none repeat scroll 0 0;
  border-radius: 2px;
  letter-spacing: 0.2em;
  padding: 2px 10px;
  position: relative;
  text-transform: uppercase;
  top: -12px;
}
.separator {
  color: #ccc;
  line-height: 1.2em;
  margin: 30px auto;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.separator::before,
.separator::after {
  border-bottom: 1px solid #eee;
  content: "";
  display: inline-block;
  height: .65em;
  margin: 0 -4px 0 -100%;
  vertical-align: top;
  width: 50%;
}
.separator::after {
  margin: 0 -100% 0 0;
}
.separator i,
.separator span,
.separator a {
  display: inline-block;
  margin: 0 20px 0 24px;
  font-size: 20px;
}
.separator.separator-left,
.separator.left i {
  float: left;
  margin: 2px 50px 0 0;
}
.separator.right i {
  float: right;
  margin: 0 0 2px 50px;
}
.separator.separator-medium-line {
  margin: 10px auto;
  width: 20%;
}
.separator.separator-small-line {
  width: 10%;
}
.separator.separator-rouned i,
.separator.separator-border-rouned i {
  background-color: #f5f5f5;
  border-radius: 50%;
  height: 40px;
  left: 0;
  line-height: 40px;
  margin: -12px auto auto;
  position: absolute;
  right: 0;
  width: 40px;
}
.separator.separator-border-rouned i {
  background: transparent;
  border: 1px solid #f5f5f5;
}
.separator.separator-rouned.separator-rouned-left i {
  margin: -12px auto auto;
  right: auto;
  left: 0;
}
.separator.separator-rouned.separator-rouned-right i {
  margin: -12px auto auto;
  right: 0;
  left: auto;
}
.separator.dotted::before,
.separator.dotted::after {
  border-bottom: 2px dotted #ccc;
}
/*
 * Shortcode: Social-Icons.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Tabs.less
 * -----------------------------------------------
*/
.tab-content {
  padding: 15px;
  border: 1px solid #eeeeee;
}
.nav-tabs {
  border: none;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li.active a,
.nav-tabs > li.active a:hover,
.nav-tabs > li.active a:focus {
  color: #111111;
  background-color: #f8f8f8;
}
.nav-tabs > li > a {
  border-radius: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: #555555;
  padding: 12px 20px;
  margin-right: 0;
}
.nav-tabs > li > a .fa {
  margin-right: 10px;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  color: #333333;
  background-color: transparent;
}
.nav-tabs > li > a i {
  font-size: 32px;
  margin-right: 20px;
  vertical-align: middle;
}
/* -------- Nav Tabs ---------- */
.nav-tabs > li {
  text-align: center;
}
.nav-tabs > li > a i {
  display: block;
  font-size: 48px;
  margin-right: 0;
  margin-bottom: 10px;
  vertical-align: middle;
}
/* -------- Horizontal-Tab-Centered ---------- */
.horizontal-tab-centered .nav-pills {
  display: inline-block;
}
.horizontal-tab-centered .nav-pills > li > a {
  background: #eeeeee;
  color: #555555;
  font-size: 14px;
  padding: 8px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.horizontal-tab-centered .nav-pills > li > a:hover {
  color: #fff;
}
.horizontal-tab-centered .nav-pills > li > a i {
  font-size: 14px;
  margin-right: 8px;
}
.horizontal-tab-centered .nav-pills > li.active > a,
.horizontal-tab-centered .nav-pills > li.active > a:hover,
.horizontal-tab-centered .nav-pills > li.active > a:focus {
  color: #fff;
}
/* -------- Horizontal-Tab Fullwide ---------- */
.horizontal-tab.fullwide .nav-tabs > li {
  width: 20%;
}
/* -------- Vertical Nav-Tabs ---------- */
.vertical-tab .tab-content {
  padding: 5px 5px 20px 20px;
  border: none;
}
.vertical-tab .nav-tabs > li {
  float: none;
  margin-bottom: 0;
}
.vertical-tab .nav-tabs > li > a {
  background: none;
  border-radius: 0;
  border-bottom: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  font-size: 14px;
  padding: 30px 0 30px 15px;
}
.vertical-tab .nav-tabs > li:first-child > a {
  border-top: 1px solid #d3d3d3 !important;
}
.vertical-tab .nav-tabs > li.active > a,
.vertical-tab .nav-tabs > li.active > a:hover,
.vertical-tab .nav-tabs > li.active > a:focus {
  background: none;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #d3d3d3;
}
.vertical-tab .small-padding .nav-tabs > li a {
  padding: 20px 0 20px 15px;
}
.vertical-tab .small-padding .nav-tabs > li i {
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  .vertical-tab .tab-content {
    padding: 20px 5px;
  }
  .nav-tabs > li > a {
    border-radius: 0;
    border-bottom: none;
    border-left: 2px solid #d3d3d3;
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    border-bottom: none;
    border-left: 2px solid #d3d3d3;
  }
}
.custom-nav-tabs {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color;
  border-image: none;
  border-style: none solid none none;
  border-width: medium 0px medium medium;
  margin-right: 53px;
}
.custom-nav-tabs > li {
  float: none;
  margin: 0 0 1px;
}
.custom-nav-tabs > li a {
  background: #eeeeee none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  display: block;
  font-size: 17px;
  font-weight: 700;
  line-height: 59px;
  margin: 0;
  outline: medium none;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
}
.custom-nav-tabs > li.active > a,
.custom-nav-tabs > li.active > a:hover,
.custom-nav-tabs > li.active > a:focus,
.custom-nav-tabs > li > a:hover,
.custom-nav-tabs > li > a:focus {
  border: medium none;
  color: #000;
  padding: 0;
}
/*
 * Shortcode: Teams.less
 * -----------------------------------------------
*/
.attorney-address li {
  background: #f8f8f8;
  margin-bottom: 15px;
  padding: 15px 20px;
}
.attorney-address li i {
  font-size: 15px;
  vertical-align: middle;
}
.attorney-address .media-body p {
  font-size: 12px;
  margin-bottom: 0;
}
.attorney-address .media-body p span {
  font-size: 16px;
  color: #555555;
  margin-right: 5px;
  padding-bottom: 15px;
}
.triangle {
  background: none repeat scroll 0 0 #fff;
  height: 15px;
  left: -7px;
  position: absolute;
  top: 20%;
  -webkit-transform: rotate(137deg);
  -ms-transform: rotate(137deg);
  -o-transform: rotate(137deg);
  transform: rotate(137deg);
  width: 15px;
}
.member-info {
  background: #eeeeee none repeat scroll 0 0;
  padding: 20px;
}
.contact-area i {
  padding-right: 10px;
}
/*
 * Shortcode: Testimonials.less
 * -----------------------------------------------
*/
.testimonial .item {
  border: 1px solid #d3d3d3;
  padding: 15px;
  position: relative;
  overflow: hidden;
}
.testimonial .item .thumb {
  max-width: 55px;
}
.testimonial .item::after {
  display: inline-block;
  height: 150px;
  left: -45px;
  position: absolute;
  top: -45px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 60px;
}
.testimonial .item::before {
  content: "\f10e";
  color: #fff;
  display: inline-block;
  font-family: fontawesome;
  font-size: 16px;
  font-style: normal;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 5px;
  z-index: 9999;
}
/*
 * Shortcode: Toggles-Accordions.less
 * -----------------------------------------------
*/
.panel-group .panel {
  box-shadow: none;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  border: none;
  position: relative;
}
.panel-group .panel-title {
  font-size: 13px;
}
.panel-group .panel-title a {
  background: rgba(238, 238, 238, 0.5);
  display: block;
  font-size: 14px;
  padding: 10px 15px 10px 48px;
  white-space: normal;
}
.panel-group .panel-title a.active {
  background: #f1f1f1 none repeat scroll 0 0 !important;
  border-bottom: 1px solid #d3d3d3 !important;
  color: #111111;
}
.panel-group .panel-title a.active .open-sub:after {
  display: none;
}
.panel-group .panel-title a.active::after {
  color: #fff;
  background: #e3e3e3 !important;
  content: "\f068";
}
.panel-group .panel-title a::after {
  color: #777777;
  content: "\f067";
  font-family: fontawesome;
  font-size: 14px;
  left: 0;
  padding: 10px 14px;
  position: absolute;
  top: 0;
}
.panel-group .panel-title:hover a,
.panel-group .panel-title:focus a {
  color: #333333;
}
.panel-group .panel-title .open-sub {
  display: block;
  position: relative;
}
.panel-group .panel-title .open-sub:before,
.panel-group .panel-title .open-sub:after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  top: 50%;
  margin-top: -0.5px;
  left: 50%;
}
.panel-group .panel-content {
  background: #f1f1f1 none repeat scroll 0 0;
  padding: 10px 20px 20px;
}
.panel-group .panel-content > :last-child {
  margin-bottom: 0;
}
/* -------- Toggle ---------- */
.panel-group.toggle .panel-heading {
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.panel-group.toggle .panel-body {
  padding: 10px 0 5px 25px;
}
.panel-group.accordion.transparent .panel-title a,
.panel-group.toggle.transparent .panel-title a,
.panel-group.accordion.transparent .panel-title a.active,
.panel-group.toggle.transparent .panel-title a.active {
  background: transparent !important;
  border: none !important;
}
.panel-group.accordion.transparent .panel-title a::after,
.panel-group.toggle.transparent .panel-title a::after,
.panel-group.accordion.transparent .panel-title a.active::after,
.panel-group.toggle.transparent .panel-title a.active::after {
  color: #333333;
  background: transparent !important;
}
.panel-group.accordion.transparent .panel-content,
.panel-group.toggle.transparent .panel-content {
  background: transparent !important;
}
.panel-group.toggle .panel-heading + .panel-collapse > .panel-body,
.panel-group.toggle .panel-heading + .panel-collapse > .list-group {
  border-top: none;
}
/*
 * Shortcode: Vertical-Timeline.less
 * -----------------------------------------------
*/
.cd-timeline-content {
  background: #f1f1f1;
}
.cd-timeline-content::before {
  border-color: transparent transparent transparent #eee;
}
.cd-timeline-block:nth-child(2n) .cd-timeline-content::before {
  border-color: transparent #eee transparent transparent;
}
#cd-timeline.cd-timeline-simple::before {
  background: #ccc none repeat scroll 0 0;
  height: 82%;
  top: 65px;
  width: 2px;
}
#cd-timeline.cd-timeline-simple.time-line-media::before {
  height: 65%;
}
.cd-timeline-simple .cd-timeline-block {
  margin: 38px 0 20px 0;
}
.cd-timeline-simple .cd-timeline-block .cd-timeline-content::before {
  display: none;
}
.cd-timeline-simple .cd-timeline-block .cd-timeline-content {
  padding-top: 0;
  background: none;
  box-shadow: none;
}
.cd-timeline-simple .cd-timeline-block .cd-timeline-content p {
  font-size: 14px;
}
.cd-timeline-simple .cd-timeline-block .cd-timeline-img {
  background: #333 none repeat scroll 0 0 !important;
  box-shadow: 0 0 0 4px #ccc, 0 3px 0 rgba(0, 0, 0, 0.06) inset, 0 0px 0 3px rgba(0, 0, 0, 0.05) !important;
}
@media only screen and (max-width: 992px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content {
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content h2 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content .cd-date {
    font-size: 14px;
  }
}
/* Vertical Masonry Timeline */
.vertical-masonry-timeline .each-masonry-item {
  position: relative;
  width: 50%;
  margin-bottom: 30px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2) {
  margin-top: 80px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n) {
  padding-left: 50px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) {
  padding-right: 50px;
}
.vertical-masonry-timeline .each-masonry-item .timeline-block {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #d7e4ed;
  height: 100%;
  padding: 20px;
  position: relative;
  width: 100%;
}
.vertical-masonry-timeline .each-masonry-item .timeline-block:before,
.vertical-masonry-timeline .each-masonry-item .timeline-block:after {
  border-style: solid;
  border-width: 8px;
  content: "";
  display: block;
  position: absolute;
  top: 20px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-block:before {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #c6d2db;
  left: auto;
  right: -16px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-block:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffffff;
  left: auto;
  right: -15px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n) .timeline-block:before {
  border-color: rgba(0, 0, 0, 0) #c6d2db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  left: -16px;
  right: auto;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n) .timeline-block:after {
  border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  left: -15px;
  right: auto;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-post-format {
  left: auto;
  right: -82px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n) .timeline-post-format {
  left: -80px;
  right: auto;
}
.timeline-post-format {
  border: 3px solid #bbb;
  border-radius: 100%;
  color: #bbb;
  height: 60px;
  overflow: hidden;
  position: absolute;
  top: -2px;
  width: 60px;
  z-index: 1;
}
.timeline-post-format:after {
  background: #fff none repeat scroll 0 0;
  color: #fff;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.timeline-post-format i {
  font-size: 18px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.vertical-masonry-timeline-wrapper:before {
  background: #ddd none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 100%;
  left: 50%;
  margin-left: 0px;
  position: absolute;
  width: 4px;
}
.vertical-masonry-timeline-wrapper:after {
  background: rgba(0, 0, 0, 0) linear-gradient(#ddd, transparent) repeat scroll 0 0;
  bottom: -100px;
  content: "";
  height: 100px;
  left: 50%;
  margin-left: 0px;
  position: absolute;
  width: 4px;
}
.vertical-masonry-timeline::after {
  background: rgba(0, 0, 0, 0) linear-gradient(transparent, #ddd) repeat scroll 0 0;
  content: "";
  height: 100px;
  left: 50%;
  position: absolute;
  top: -65px;
  width: 4px;
}
@media only screen and (min-width: 768px) {
  .vertical-masonry-timeline .each-masonry-item.item-right {
    padding-right: 0px;
    padding-left: 50px;
  }
  .vertical-masonry-timeline .each-masonry-item.item-right .timeline-block:before {
    border-color: rgba(0, 0, 0, 0) #c6d2db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    left: -16px;
    right: auto;
  }
  .vertical-masonry-timeline .each-masonry-item.item-right .timeline-block:after {
    border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    left: -15px;
    right: auto;
  }
  .vertical-masonry-timeline .each-masonry-item.item-right .timeline-post-format {
    left: -80px;
    right: auto;
  }
  .vertical-masonry-timeline .each-masonry-item.item-left {
    padding-right: 50px;
    padding-left: 0px;
  }
  .vertical-masonry-timeline .each-masonry-item.item-left .timeline-block:before {
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #c6d2db;
    right: -16px;
    left: auto;
  }
  .vertical-masonry-timeline .each-masonry-item.item-left .timeline-block:after {
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffffff;
    right: -15px;
    left: auto;
  }
  .vertical-masonry-timeline .each-masonry-item.item-left .timeline-post-format {
    right: -81px;
    left: auto;
  }
}
@media only screen and (max-width: 768px) {
  .vertical-masonry-timeline .each-masonry-item {
    width: 100%;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) {
    padding-right: 0px;
    padding-left: 71px;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n) {
    padding-left: 71px;
    padding-right: inherit;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2) {
    margin-top: 0;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-post-format {
    left: -72px;
    right: auto;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n) .timeline-post-format {
    left: -72px;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-block::before {
    border-color: rgba(0, 0, 0, 0) #c6d2db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    left: -16px;
    right: auto;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-block::after {
    border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    left: -15px;
    right: auto;
  }
  .vertical-masonry-timeline:after {
    left: 29px;
  }
  .vertical-masonry-timeline-wrapper:before,
  .vertical-masonry-timeline-wrapper:after {
    left: 44px;
  }
}
/*
 * Shortcode: Working-Process.less
 * -----------------------------------------------
*/
.working-process > li {
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.working-process > li::after {
  border-top: 1px dashed #333333;
  content: "";
  left: 6px;
  margin-left: -32px;
  overflow: hidden;
  position: absolute;
  top: 25px;
  width: 50%;
}
.working-process > li::before {
  border-top: 1px dashed #333333;
  content: "";
  margin-right: -25px;
  position: absolute;
  right: 0;
  top: 25px;
  width: 50%;
}
.working-process > li :first-child::after {
  border-top: medium none;
}
.working-process > li :last-child::before {
  border-top: medium none;
}
.working-process li a {
  border: 1px solid;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  width: 50px;
  border-radius: 50%;
}
.working-process li a:hover {
  background: #333333 none repeat scroll 0 0;
  border: 1px solid transparent;
  color: #fff;
}
.working-process li a i {
  font-size: 18px;
}
.working-process.square li a,
.working-process.square.large-circle li a {
  border-radius: 0;
}
.working-process.large-circle li a {
  height: 120px;
  line-height: 130px;
  width: 120px;
  border-radius: 50%;
}
.working-process.large-circle li a i {
  font-size: 28px;
}
.working-process.large-circle > li::before {
  margin-right: -60px;
  top: 60px;
}
.working-process.large-circle > li::after {
  margin-left: -64px;
  top: 60px;
}
.working-process.theme-colored li a {
  color: #fff;
}
/*
 * Widgets.less
 * -----------------------------------------------
*/
/* -------- Top Search Bar ---------- */
.search-form-wrapper {
  position: relative;
  /* form absolute */
}
.search-form-wrapper.toggle {
  display: none;
}
.search-form-wrapper.toggle.active {
  display: block;
}
.search-form-wrapper form {
  padding: 10px;
  position: relative;
}
.search-form-wrapper form label {
  margin: 0px;
  position: absolute;
  right: 11px;
  top: 11px;
}
.search-form-wrapper form label:before {
  color: #555555;
  cursor: pointer;
  content: "\f002";
  font-family: fontawesome;
  font-size: 16px;
  position: absolute;
  right: 13px;
  top: 6px;
}
.search-form-wrapper form input[type="text"] {
  background-color: #fff;
  border: 1px solid #c0c0c0;
  height: 46px;
  padding: 8px 17px;
  width: 100%;
}
.search-form-wrapper form input[type="submit"] {
  background-color: #fff;
  border-left: 1px solid #c0c0c0;
  border: none;
  height: 44px;
  width: 50px;
  text-indent: -999px;
}
.search-form-wrapper.form-absolute form {
  background-color: #333333;
  position: absolute;
  right: 0px;
  top: 12px;
  width: 370px;
  z-index: 99;
}
/* -------- Form Round ---------- */
.search-form-wrapper.round form input[type="text"] {
  border-radius: 30px;
  border-width: 4px;
}
.search-form-wrapper.round form input[type="submit"] {
  height: auto;
  width: auto;
  margin-right: 10px;
  margin-top: 10px;
}
.search-form-wrapper.round form label::before {
  color: #c0c0c0;
}
.widget .list-border li {
  border-bottom: 1px dashed #d3d3d3;
}
.widget.dark .list-border li {
  border-bottom: 1px dashed #333333;
}
/* -------- Footer & Sidebar Widgets ---------- */
.widget {
  margin-bottom: 40px;
}
.widget .widget-title {
  margin-top: 0;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.widget ul li,
.widget .post {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.widget.brochures > li {
  margin-bottom: 10px;
  padding: 10px;
}
.widget.brochures i {
  color: #666666;
  font-size: 16px;
  margin-right: 10px;
}
.widget.address > li {
  font-size: 13px;
  margin-bottom: 10px;
  padding: 5px 10px;
}
.widget.address > li i {
  font-size: 16px;
  margin-right: 8px;
  vertical-align: middle;
}
.widget .twitter-feed li {
  font-size: 13px;
  margin-bottom: 20px;
  margin-top: 0px;
  padding-left: 45px;
}
.widget .twitter-feed li.item {
  position: relative;
}
.widget .twitter-feed li::after {
  content: "\f099";
  font-size: 24px;
  font-family: fontawesome;
  left: 0;
  position: absolute;
  top: 0;
}
.widget .social-icons li {
  margin-bottom: 0;
  padding-bottom: 0;
}
.widget .social-icons li a {
  margin-bottom: 0;
}
.widget .tags a {
  border: 1px solid #d3d3d3;
  display: inline-block;
  font-size: 11px;
  margin: 5px 4px 5px -2px;
  padding: 5px 10px;
}
.widget .tags a:hover {
  color: #fff;
}
.widget .search-input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #c0c0c0;
  border-radius: initial;
  box-shadow: none;
  height: 45px;
  padding: 10px;
}
.widget .search-button {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #c0c0c0;
  border-radius: 0;
  height: 45px;
}
.widget .nav-tabs li {
  border-bottom: 1px solid transparent;
  margin-bottom: -2px;
  padding-bottom: 0;
}
.widget .nav-tabs li:hover {
  border-bottom: 1px solid transparent;
}
.widget .post-title a {
  font-size: 13px;
  letter-spacing: 0;
}
.widget .quick-contact-form .form-control {
  background-color: rgba(241, 241, 241, 0.8);
  border-color: #d3d3d3;
  color: #a9a9a9;
}
.widget .product-list .product-title {
  font-size: 14px;
  font-weight: 400;
}
.widget.dark .widget-title {
  color: #fff;
}
.widget.dark .tags a {
  background: #555555;
  border: 1px solid #333333;
  color: #fff;
}
.widget.dark .widget-image-carousel .title {
  color: #fff;
}
.widget.dark .search-input {
  border: 1px solid #333333;
}
.widget.dark .social-icons li a:hover i {
  color: #fff;
}
.widget.dark .search-button {
  border: 1px solid #333333;
}
.widget.dark .widget-subscribe .subscribe-title {
  color: #fff;
}
.widget.dark .widget-subscribe .subscribe-sub-title {
  color: #666666;
}
.widget.dark .nav-tabs li {
  border-bottom: 1px solid transparent;
  margin-bottom: -2px;
  padding-bottom: 0;
}
.widget.dark .nav-tabs li:hover {
  border-bottom: 1px solid transparent;
}
.widget.dark .nav-tabs li:hover a {
  border: 1px solid #555555;
}
.widget.dark .nav-tabs li.active a {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #333333 none repeat scroll 0 0;
  border-color: #555555 #555555 transparent;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}
.widget.dark .product-title a {
  color: #808080;
}
.widget.dark .post-title a {
  color: #c0c0c0;
}
.widget.dark .tab-content {
  background: #333333 none repeat scroll 0 0;
  border: 1px solid #555555;
}
.widget.dark .quick-contact-form .form-control {
  background-color: #252525;
  border-color: #333333;
}
.widget.no-border ul li {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
/* --------Horizontal Contact Widget  ---------- */
.horizontal-contact-widget {
  border-top: 1px dashed #d3d3d3;
}
.horizontal-contact-widget .each-widget::before {
  content: "\f111";
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  right: 0;
  top: -42px;
}
/* -------- Horizontal Contact Widget Dark ---------- */
.horizontal-contact-widget.dark {
  border-top: 1px dashed #333333;
}
.widget.dark .btn.btn-default {
  border-color: #333;
}
/* -------- Flickr-Feed ---------- */
#flickr-feed img {
  background: none repeat scroll 0 0 #000;
  float: left;
  height: 60px;
  margin: 0 5px 5px 0;
  padding: 0;
  width: 70px;
}
.text-hover-theme-colored {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/*
 * Footer.less
 * -----------------------------------------------
*/
.footer {
  background: #fafafa;
  padding: 60px 0;
}
.footer a {
  color: #808080;
}
.footer a:focus,
.footer a:hover,
.footer a:active {
  color: #666666;
}
.footer .widget .widget-title {
  margin-top: 0px;
}
.footer .widget.dark .quick-contact-form button {
  background-color: #252525;
  border-color: #333333;
  color: #909090;
}
.footer .widget.dark .quick-contact-form button:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #333333;
}
