/*
Boxed Skin
*/

/* Menu items */
.menuzord-menu > li{
	padding: 18px 0;
}
.menuzord-menu > li > a{
	padding: 8px 16px;
}

/* Active/hover state (menu items) */
.menuzord-menu > li.active > a,
.menuzord-menu > li:hover > a{
	background: #333;
	color: #fff;
}

/* Dropdown */
.menuzord-menu ul.dropdown{
	top: 71px;
}
.menuzord-menu ul.dropdown li ul.dropdown{
    top: 0;
}

/* Dropdown items */
.menuzord .menuzord-menu ul.dropdown li:hover > a{
	background: #333;
	color: #fff;
}

/* Megamenu */
.menuzord-menu > li > .megamenu{
	top: 71px;
}

/* Search Field */
.menuzord-menu > li.search form{
	padding: 2px 16px;
}

/* Color schemes */
/* blue */
.menuzord.blue .menuzord-menu > li.active > a, 
.menuzord.blue .menuzord-menu > li:hover > a, 
.menuzord.blue .menuzord-menu ul.dropdown li:hover > a{ 
	background: #5D9CEC; 
	color: #fff;
}
/* green */
.menuzord.green .menuzord-menu > li.active > a, 
.menuzord.green .menuzord-menu > li:hover > a, 
.menuzord.green .menuzord-menu ul.dropdown li:hover > a{ 
	background: #48CFAD;
	color: #fff;
}
/* red */
.menuzord.red .menuzord-menu > li.active > a, 
.menuzord.red .menuzord-menu > li:hover > a, 
.menuzord.red .menuzord-menu ul.dropdown li:hover > a{ 
	background: #FC6E51;
	color: #fff;
}
/* orange */
.menuzord.orange .menuzord-menu > li.active > a, 
.menuzord.orange .menuzord-menu > li:hover > a, 
.menuzord.orange .menuzord-menu ul.dropdown li:hover > a{ 
	background: #ff853f;
	color: #fff;
}
/* yellow */
.menuzord.yellow .menuzord-menu > li.active > a, 
.menuzord.yellow .menuzord-menu > li:hover > a, 
.menuzord.yellow .menuzord-menu ul.dropdown li:hover > a{ 
	background: #FFCE54;
	color: #fff;
}
/* purple */
.menuzord.purple .menuzord-menu > li.active > a, 
.menuzord.purple .menuzord-menu > li:hover > a, 
.menuzord.purple .menuzord-menu ul.dropdown li:hover > a{ 
	background: #AC92EC;
	color: #fff;
}
/* pink */
.menuzord.pink .menuzord-menu > li.active > a, 
.menuzord.pink .menuzord-menu > li:hover > a, 
.menuzord.pink .menuzord-menu ul.dropdown li:hover > a{ 
	background: #EC87C0;
	color: #fff;
}

/* Mobile mode (Responsive mode) */
@media (max-width: 900px){
	/* Menu items */
	.menuzord-responsive .menuzord-menu > li{
		padding: 0;
	}
	.menuzord-responsive .menuzord-menu > li > a{
		padding: 12px 20px !important;
	}
}